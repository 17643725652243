export const MAKE_REQ = "MAKE_REQ";
export const REQ_GETALL_SUCC = "REQ_GETALL_SUCC";
export const REQ_GETALL_FAIL = "REQ_GETALL_FAIL";

export const REQ_GETEMAILDETAILS_SUCC = "REQ_GETEMAILDETAILS_SUCC";
export const REQ_GETEMAILDETAILS_FAIL = "REQ_GETEMAILDETAILS_FAIL";

export const FETCH_DDEMAILS_REQUEST = "FETCH_DDEMAILS_REQUEST";
export const FETCH_DDEMAILS_SUCCESS = "FETCH_DDEMAILS_SUCCESS";
export const FETCH_DDEMAILS_FAILURE = "FETCH_DDEMAILS_FAILURE";


export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_FAIL = "UPLOAD_FAIL";

export const GET_ACTIVE_USER_SUCC = "GET_ACTIVE_USER_SUCC";
export const GET_ACTIVE_USER_FAIL = "GET_ACTIVE_USER_FAIL";

export const UPDATE_USER_SUCC = "UPDATE_USER_SUCC";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const CHANGE_PASSWORD_SUCC = "CHANGE_PASSWORD_SUCC";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_FAIL = "FETCH_USER_DETAILS_FAIL";

export const UPDATE_EDIT_USER_SUCCESS = "UPDATE_EDIT_USER_SUCCESS";
export const UPDATE_EDIT_USER_FAILURE = "UPDATE_EDIT_USER_FAILURE";
export const UPDATE_EDIT_USER_ERROR = "UPDATE_EDIT_USER_ERROR";


// export const MAKE_REQ = "MAKE_REQ";
// export const REQ_GETALL_SUCC = "REQ_GETALL_SUCC";
// export const REQ_GETALL_FAIL = "REQ_GETALL_FAIL";
export const OPEN_POPUP = "OPEN_POPUP";
export const REQ_ADD_SUCC = "REQ_ADD_SUCC";
export const REQ_UPDATE_SUCC = "REQ_UPDATE_SUCC";
export const REQ_DELETE_SUCC = "REQ_DELETE_SUCC";
export const REQ_GETBYCODE_SUCC = "REQ_GETBYCODE_SUCC";
export const FAIL_REQUEST = "FAIL_REQUEST";

// export const GET_ACTIVE_USER_SUCC = 'GET_ACTIVE_USER_SUCC';
// export const GET_ACTIVE_USER_FAIL = 'GET_ACTIVE_USER_FAIL';
// export const UPDATE_USER_SUCC = 'UPDATE_USER_SUCC';
// export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
// export const CHANGE_PASSWORD_SUCC = 'CHANGE_PASSWORD_SUCC';
// export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const REQ_GETUSERS_SUCC = 'REQ_GETUSERS_SUCC';
export const REQ_GETUSERS_FAIL = 'REQ_GETUSERS_FAIL';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_SUCCESS';

export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";
// export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

// ======= For Card List ====================
export const FETCH_CARDS_REQUEST = "FETCH_CARDS_REQUEST";
export const FETCH_CARDS_SUCCESS = "FETCH_CARDS_SUCCESS";
export const FETCH_CARDS_FAIL = "FETCH_CARDS_FAIL";

// ======= For Card Detail ====================
export const GET_CARD_DETAILS_SUCCESS = "GET_CARD_DETAILS_SUCCESS";
export const GET_CARD_DETAILS_FAIL = "GET_CARD_DETAILS_FAIL";

// ======= For Card Delete/Expire ====================
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAIL = 'DELETE_CARD_FAIL';

// ======== For Settings =========================
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

export const SAVE_SETTINGS_SUCCESS = "SAVE_SETTINGS_SUCCESS";
export const SAVE_SETTINGS_FAILURE = "SAVE_SETTINGS_FAILURE";

export const FETCH_TOKEN_REQUEST = "FETCH_TOKEN_REQUEST"; 
export const FETCH_TOKEN_SUCCESS = "FETCH_TOKEN_SUCCESS";
export const FETCH_TOKEN_FAILURE = "FETCH_TOKEN_FAILURE";


// ======= For Log ====================
export const FETCH_LOGS_REQUEST = "FETCH_LOGS_REQUEST";
export const FETCH_LOGS_SUCCESS = "FETCH_LOGS_SUCCESS";
export const FETCH_LOGS_FAIL = "FETCH_LOGS_FAIL";


// =======================================================
export const FETCH_CARDS_HISTORY_REQUEST = 'FETCH_CARDS_HISTORY_REQUEST';
export const FETCH_CARDS_HISTORY_SUCCESS = 'FETCH_CARDS_HISTORY_SUCCESS';
export const FETCH_CARDS_HISTORY_FAILURE = 'FETCH_CARDS_HISTORY_FAILURE';

// ========================= upload exp csv ====================
export const UPLOAD_EXPIRE_CSV_REQUEST = 'UPLOAD_EXPIRE_CSV_REQUEST';
export const UPLOAD_EXPIRE_CSV_SUCCESS = 'UPLOAD_EXPIRE_CSV_SUCCESS';
export const UPLOAD_EXPIRE_CSV_FAILURE = 'UPLOAD_EXPIRE_CSV_FAILURE';


// ========================= upload new email csv ====================
export const UPLOAD_CSV_REQUEST = 'UPLOAD_CSV_REQUEST';
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';
export const UPLOAD_CSV_FAILURE = 'UPLOAD_CSV_FAILURE';


