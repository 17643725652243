// ResponseDialog.jsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const ErrorList = styled(Box)({
  maxHeight: 220,
  overflowY: "auto",
  border: "1px solid #ccc",
  marginTop: 8,
  padding: 4,
  backgroundColor: "#fff",
  borderRadius: 4,
});

const StyledListItem = styled(ListItem)({
  borderBottom: "1px solid #eee",
  "&:last-child": {
    borderBottom: "none",
  },
});

const DDResponseDialog = ({ open, handleClose, uploadResponse }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="response-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        id="response-dialog-title"
        sx={{ backgroundColor: "#444", color: "#ffffff" }}
      >
        Upload Response
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {uploadResponse && (
          <Box>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={3}>
                <Typography
                  color="#000"
                  variant="h6"
                  sx={{ fontWeight: "700" }}
                >
                  Total
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="h6"
                  sx={{ fontWeight: "400" }}
                >
                  {uploadResponse.total}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  color="#000"
                  variant="h6"
                  sx={{ fontWeight: "700" }}
                >
                  Inserted
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="h6"
                  sx={{ fontWeight: "400" }}
                >
                  {uploadResponse.inserted}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  color="#000"
                  variant="h6"
                  sx={{ fontWeight: "700" }}
                >
                  Scheduled
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="h6"
                  sx={{ fontWeight: "400" }}
                >
                  {uploadResponse.scheduled}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  color="#000"
                  variant="h6"
                  sx={{ fontWeight: "700" }}
                >
                  Errors
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="h6"
                  sx={{ fontWeight: "400" }}
                >
                  {uploadResponse.error_cnt}
                </Typography>
              </Grid>
            </Grid>

            {/* <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "medium" }}
            >
              List of Errors:
            </Typography> */}
            <ErrorList>
              <List dense>
                {uploadResponse.errors.map((error, index) => (
                  <StyledListItem key={index}>
                    <Typography variant="body2">{error}</Typography>
                  </StyledListItem>
                ))}
              </List>
            </ErrorList>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DDResponseDialog;
