import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { uploadCSV } from "../../../../Redux/ActionCreator";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import ResponseDialog from "./ResponseDialog";

const Input = styled("input")({
  display: "none",
});

const DottedBox = styled(Box)(({ theme }) => ({
  border: "2px dashed #aaa",
  borderRadius: "3px",
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: 140,
  gap: theme.spacing(2),
}));

const EmailNewImport = ({ refreshData }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  // const [helloDialogOpen, setHelloDialogOpen] = useState(false);
  const fileInputRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setIsFilePicked(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".csv")) {
      setFile(file);
      setIsFilePicked(true);
    } else {
      toast.error("Please select a CSV file.");
      setIsFilePicked(false);
    }
  };

  const handleUpload = () => {
    if (file) {
      dispatch(uploadCSV(file))
        .then((response) => {
          setUploadResponse(response); // Store the response directly
          handleClose(); // Close the upload dialog
          setResponseDialogOpen(true); // Open the response dialog
        })
        .catch((error) => {
          toast.error("Upload failed!");
        });
    } else {
      toast.error("No file selected or invalid file type.");
    }
  };

  const handleResponseDialogClose = () => {
    setResponseDialogOpen(false);
    setUploadResponse(null);
    refreshData();
  };

  // const handleHelloDialogClose = () => {
  //   setHelloDialogOpen(false);
  // };

  const handleCancelFile = () => {
    setFile(null);
    setIsFilePicked(false);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <Button
        startIcon={<UploadIcon />}
        onClick={handleOpen}
        variant="contained"
        sx={{ backgroundColor: "#20a1a1", color: "#ffffff" }}
      >
        Import
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="import-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          id="import-dialog-title"
          sx={{ backgroundColor: "#444", color: "#ffffff" }}
        >
          Import CSV
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DottedBox
            onClick={triggerFileInput}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(event) => {
              event.preventDefault();
              const files = event.dataTransfer.files;
              if (files.length > 0 && files[0].name.endsWith(".csv")) {
                setFile(files[0]);
                setIsFilePicked(true);
              } else {
                toast.error("Please drop a CSV file.");
              }
            }}
          >
            <CloudUploadIcon color="disabled" style={{ fontSize: 48 }} />
            <Typography>Drag and drop or click to browse your file.</Typography>
            <Input
              accept=".csv"
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </DottedBox>
          {isFilePicked && (
            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <Typography>File: {file.name}</Typography>
              <Button
                onClick={handleCancelFile}
                size="small"
                sx={{ fontSize: "0.7rem", textTransform: "none" }}
              >
                Remove
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleUpload}
            disabled={!isFilePicked}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <ResponseDialog
        open={responseDialogOpen}
        handleClose={handleResponseDialogClose}
        uploadResponse={uploadResponse}
      />
    </>
  );
};

export default EmailNewImport;
