import { Box, Typography } from "@mui/material";
import Sidebar from "../../global/Sidebar";
import LogTable from "./LogTable";
const Logs = () => {
  return (
    <Box sx={{ display: "flex" }} mt={2}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
        {/* <Typography variant="h3" component="h2" mb={3} sx={{fontWeight:'300'}}>Settings</Typography> */}
        <LogTable />
      </Box>
    </Box>
  )
}

export default Logs