import { MAKE_REQ, GET_ACTIVE_USER_SUCC, GET_ACTIVE_USER_FAIL } from "./ActionType";

const initialState = {
  isLoading: false,
  activeUser: null,
  errorMessage: "",
};

const activeUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_REQ:
      return { ...state, isLoading: true };
    case GET_ACTIVE_USER_SUCC:
      return {
        ...state,
        isLoading: false,
        activeUser: action.payload,
        errorMessage: "",
      };
    case GET_ACTIVE_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default activeUserReducer;
