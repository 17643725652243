import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

function LogDetailsDialog({ open, onClose, log }) {
  const renderListItem = (label, value) => (
    <ListItem>
      <ListItemText
        primary={label}
        secondary={value}
        primaryTypographyProps={{
          fontWeight: 700,
          fontSize: "0.8em",
        }}
      />
    </ListItem>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="log-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="log-dialog-title"
        sx={{
          backgroundColor: "#444",
          color: "#ffffff",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InfoIcon />
          <Typography ml={1}>Log Details</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {log ? (
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <List>
                {renderListItem("ID", log.id)}
                {renderListItem("Date", log.createdon)}
              </List>
            </Grid>
            <Grid item xs={3}>
              <List>
                {renderListItem("Level", log.level_name)}
                {renderListItem("Method", log.method)}
              </List>
            </Grid>
            <Grid item xs={3}>
              <List>
                {renderListItem("Username", log.username)}
                {renderListItem("User ID", log.user_id)}
              </List>
            </Grid>
            <Grid item xs={3}>
              <List>{renderListItem("IP Address", log.ip)}</List>
            </Grid>
            <Grid item xs={12}>
              {renderListItem("URL", log.url)}
              {renderListItem("Message", log.message)}
            </Grid>
          </Grid>
        ) : (
          <Typography>No log details available.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LogDetailsDialog;
