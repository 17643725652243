
export const getStatusStyle = (status) => {
  switch (status) {
    case "delivered":
      return "rgb(76, 175, 80)";
    case "accepted":
      return "rgb(33, 150, 243)"; 
    case "failed permanent":
      return "rgb(244, 67, 54)";
    case "failed temporary":
      return "rgb(255, 152, 0)";
    default:
      return "rgb(96, 125, 139)";
  }
};
