import { Box } from "@mui/material";
import Sidebar from "../../global/Sidebar";
import UserListTable from "./UserListTable";

const UserList = () => {
  return (
    <Box sx={{ display: "flex" }} mt={2}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
        {/* <Typography variant="h3" component="h2" sx={{fontWeight:'300'}}>User List</Typography> */}
        <UserListTable />
      </Box>
    </Box>
  )
}

export default UserList