import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getEmailList } from "../../../Redux/ActionCreator";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

const StatusGrid = ({ totalEmails, loadEmails, emailList }) => {
  const [filter] = useState("");

  const { results = [] } = emailList || {};
  useEffect(() => {
    loadEmails(filter);
  }, [filter, loadEmails]);

  const getStatusCounts = (status, severity) => {
    return results.filter((email) => {
      const matchesStatus = email.status === status;
      const matchesSeverity = severity ? email.severity === severity : true;
      return matchesStatus && matchesSeverity;
    }).length;
  };

  // const acceptedCount = getStatusCounts("accepted");
  // const deliveredCount = getStatusCounts("delivered");
  const failedPermanentCount = getStatusCounts("failed", "permanent");
  const failedTemporaryCount = getStatusCounts("failed", "temporary");
  const totalFailedEmails = failedPermanentCount + failedTemporaryCount;

  return (
    <Box sx={{ p: 1, backgroundColor: "#f1f3f4" }}>
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: "0",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Avatar sx={{ bgcolor: "#ffe0b2", width: 50, height: 50 }}>
              <EmailIcon sx={{ color: "#ff6f00" }} />
            </Avatar>
            <Box>
              <Typography
                variant="h5"
                component="div"
                sx={{ mt: 1, fontWeight: "bold" }}
              >
                New Member Failures
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 1.5 }}
              >
                Current totals of failed new member emails
              </Typography>
            </Box>
          </Box>
          <Box>
            <Grid container sx={{ mt: 1, justifyContent: "center" }}>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#5f6368", fontWeight: "700" }}
                  gutterBottom
                >
                  Total
                </Typography>
                <Typography
                  variant="h3"
                  fontSize="1em"
                  color="#ea4335"
                  fontWeight="bold"
                >
                  {totalFailedEmails}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#5f6368", fontWeight: "700" }}
                  gutterBottom
                >
                  Permanent
                </Typography>
                <Typography
                  variant="h3"
                  fontSize="1em"
                  color="#ea4335"
                  fontWeight="bold"
                >
                  {failedPermanentCount}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#5f6368", fontWeight: "700" }}
                  gutterBottom
                >
                  Temporary
                </Typography>
                <Typography
                  variant="h3"
                  fontSize="1em"
                  color="#ea4335"
                  fontWeight="bold"
                >
                  {failedTemporaryCount}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  totalEmails: state.emails.total,
  emailList: state.emails.emails,
});

const mapDispatchToProps = (dispatch) => ({
  loadEmails: (filter) => dispatch(getEmailList(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusGrid);
