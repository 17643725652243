import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchDDEmails } from "../../../Redux/ActionCreator";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

const DDEmailStatusGrid = ({ loadEmails, ddEmailList }) => {
  const { ddEmails: results = [] } = ddEmailList || {};

  useEffect(() => {
    loadEmails("");
  }, [loadEmails]);

  const getStatusCounts = (status, severity = null) => {
    return results.filter((email) => {
      return (
        email.status === status &&
        (severity ? email.severity === severity : true)
      );
    }).length;
  };

  const failedPermanentCount = getStatusCounts("failed", "permanent");
  const failedTemporaryCount = getStatusCounts("failed", "temporary");
  const totalFailedEmails = failedPermanentCount + failedTemporaryCount;

  return (
    <Box sx={{ p: 1, backgroundColor: "#f1f3f4" }}>
      <Card sx={{ borderRadius: 2, boxShadow: "0" }}>
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <Avatar sx={{ bgcolor: "#ffe0b2", width: 50, height: 50 }}>
              <EmailIcon sx={{ color: "#ff6f00" }} />
            </Avatar>
            <Box>
              <Typography
                variant="h5"
                component="div"
                sx={{ mt: 1, fontWeight: "bold" }}
              >
                DD Notification Failures
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 1.5 }}
              >
                Current totals of failed DD notification emails
              </Typography>
            </Box>
          </Box>
          <Grid container sx={{ mt: 1, justifyContent: "center" }}>
            <Grid item xs={12} md={3}>
              <Typography
                variant="subtitle1"
                sx={{ color: "#5f6368", fontWeight: "700" }}
                gutterBottom
              >
                Total
              </Typography>
              <Typography
                variant="h3"
                fontSize="1em"
                color="#ea4335"
                fontWeight="bold"
              >
                {totalFailedEmails}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant="subtitle1"
                sx={{ color: "#5f6368", fontWeight: "700" }}
                gutterBottom
              >
                Permanent
              </Typography>
              <Typography
                variant="h3"
                fontSize="1em"
                color="#ea4335"
                fontWeight="bold"
              >
                {failedPermanentCount}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                variant="subtitle1"
                sx={{ color: "#5f6368", fontWeight: "700" }}
                gutterBottom
              >
                Temporary
              </Typography>
              <Typography
                variant="h3"
                fontSize="1em"
                color="#ea4335"
                fontWeight="bold"
              >
                {failedTemporaryCount}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  ddEmailList: state.ddEmail,
});

const mapDispatchToProps = (dispatch) => ({
  loadEmails: (filter) => dispatch(fetchDDEmails(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DDEmailStatusGrid);
