import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Fade,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Person4Icon from "@mui/icons-material/Person4";

const ProfileButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();
  const show = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToProfile = () => {
    navigate("/profile");
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}active`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.id) {
          setUserDetails(data);
        } else {
          toast.error("Failed to fetch profile details.");
        }
      })
      .catch((error) => {
        toast.error("Error fetching profile details: " + error.message);
      });
  }, []);

  const handleLogout = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}logout`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          sessionStorage.removeItem("jwtToken");
          navigate("/login");
          toast.success("Logged out successfully!");
        } else {
          toast.error("Failed to log out.");
        }
      })
      .catch((error) => {
        toast.error("Logout failed: " + error.message);
      });
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, color: 'primary.main' }}
            aria-haspopup="true"
          >
              <Avatar
              sx={{
                width: 48,
                height: 48,
                bgcolor: '#f5803c',
                color: 'common.white',
                border: '2px solid',
                borderColor: 'background.paper'
              }}
            >
              {userDetails
                ? userDetails.nickname.split(" ").slice(0, 2).join(" ")
                : ""}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={show}
        onClose={handleClose}
        onClick={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiPaper-root': {
            minWidth: '220px',
            boxShadow: '2px 2px 10px rgba(0,0,0,0.2)',
            borderRadius: 2,
            overflow: 'visible',
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
              boxShadow: '-1px -1px 2px rgba(0,0,0,0.1)'
            }
          }
        }}
      >
        <Box sx={{ p: 2, bgcolor: 'background.paper', display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
            {userDetails ? userDetails.forename : ""}
          </Typography>
          <Typography variant="subtitle2" component="div">
            ({userDetails ? userDetails.user_type : ""})
          </Typography>
        </Box>
        <Divider />
        <MenuItem onClick={handleToProfile}>
          <ListItemIcon>
            <Person4Icon/>
          </ListItemIcon>
          Profile
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileButton;
