import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// export const tokens = (mode) => ({
//   ...(mode === "dark"
//     ? {
//         grey: {
//           100: "#e0e0e0",
//           200: "#c2c2c2",
//           300: "#a3a3a3",
//           400: "#858585",
//           500: "#666666",
//           600: "#525252",
//           700: "#3d3d3d",
//           800: "#292929",
//           900: "#141414",
//         },
//         orange: {
//           100: "#ffe5cc",
//           200: "#ffcc99",
//           300: "#ffae66",
//           400: "#ff8a33",
//           500: "#ff6a13",
//           600: "#ff5d00",
//           700: "#ff4f00",
//           800: "#ff4100",
//           900: "#ff3300",
//         },
//         primary: {
//           100: "#d0d1d5",
//           200: "#a1a4ab",
//           300: "#727681",
//           400: "#1F2A40",
//           500: "#141b2d",
//           600: "#101624",
//           700: "#0c101b",
//           800: "#080b12",
//           900: "#040509",
//         },
//         greenAccent: {
//           100: "#dbf5ee",
//           200: "#b7ebde",
//           300: "#94e2cd",
//           400: "#70d8bd",
//           500: "#4cceac",
//           600: "#3da58a",
//           700: "#2e7c67",
//           800: "#1e5245",
//           900: "#0f2922",
//         },
//         redAccent: {
//           100: "#f8dcdb",
//           200: "#f1b9b7",
//           300: "#e99592",
//           400: "#e2726e",
//           500: "#db4f4a",
//           600: "#af3f3b",
//           700: "#832f2c",
//           800: "#58201e",
//           900: "#2c100f",
//         },
//         blueAccent: {
//           100: "#e1e2fe",
//           200: "#c3c6fd",
//           300: "#a4a9fc",
//           400: "#868dfb",
//           500: "#6870fa",
//           600: "#535ac8",
//           700: "#3e4396",
//           800: "#2a2d64",
//           900: "#151632",
//         },
//       }
//     : {
//         grey: {
//           100: "#141414",
//           200: "#292929",
//           300: "#3d3d3d",
//           400: "#525252",
//           500: "#666666",
//           600: "#858585",
//           700: "#a3a3a3",
//           800: "#c2c2c2",
//           900: "#e0e0e0",
//         },
//         orange: {
//           100: "#ff3300",
//           200: "#ff4100",
//           300: "#ff4f00",
//           400: "#ff5d00",
//           500: "#ff6a13",
//           600: "#ff8a33",
//           700: "#ffae66",
//           800: "#ffcc99",
//           900: "#ffe5cc",
//         },        
//         primary: {
//           100: "#040509",
//           200: "#080b12",
//           300: "#0c101b",
//           400: "#f2f0f0", // manually changed
//           500: "#141b2d",
//           600: "#1F2A40",
//           700: "#727681",
//           800: "#a1a4ab",
//           900: "#d0d1d5",
//         },
//         greenAccent: {
//           100: "#0f2922",
//           200: "#1e5245",
//           300: "#2e7c67",
//           400: "#3da58a",
//           500: "#4cceac",
//           600: "#70d8bd",
//           700: "#94e2cd",
//           800: "#b7ebde",
//           900: "#dbf5ee",
//         },
//         redAccent: {
//           100: "#2c100f",
//           200: "#58201e",
//           300: "#832f2c",
//           400: "#af3f3b",
//           500: "#db4f4a",
//           600: "#e2726e",
//           700: "#e99592",
//           800: "#f1b9b7",
//           900: "#f8dcdb",
//         },
//         blueAccent: {
//           100: "#151632",
//           200: "#2a2d64",
//           300: "#3e4396",
//           400: "#535ac8",
//           500: "#6870fa",
//           600: "#868dfb",
//           700: "#a4a9fc",
//           800: "#c3c6fd",
//           900: "#e1e2fe",
//         },
//       }),
// });
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        // Dark mode colors
        primary: {
          main: "#90caf9", // Lighter blue for better visibility on dark backgrounds
          contrastText: "#000000" // Ensuring readability
        },
        secondary: {
          main: "#f48fb1", // Soft pink for secondary actions
          contrastText: "#000000"
        },
        error: {
          main: "#f44336" // Red for errors
        },
        background: {
          default: "#121212", // Darker shade for background
          paper: "#1e1e1e"
        },
        text: {
          primary: "#ffffff",
          secondary: "#bbbbbb"
        },
        orange: {
          main: "#EA4335",
          contrastText: "#000000" 
        },
        white: {
          main: "#000000",
          contrastText: "#ffffff"
        }
        ,
        black: {
          main: "#eee",
          contrastText: "#444"
        },
        purple: {
          light: '#9c27b0',
          main: '#ab47bc',
          dark: '#ba68c8',
          contrastText: '#fff',
        },
        blue: {
          light: '#2196f3',
          main: '#42a5f5',
          dark: '#64b5f6',
          contrastText: '#fff',
        }
      }
    : {
        // Light mode colors
        primary: {
          main: "#1976d2", // Stronger blue for light mode
          contrastText: "#ffffff"
        },
        secondary: {
          main: "#ec407a", // Vibrant pink for contrast
          contrastText: "#ffffff"
        },
        error: {
          main: "#d32f2f" // Darker red for clear visibility
        },
        background: {
          default: "#eeeeee", // Light grey to reduce strain
          paper: "#ffffff"
        },
        text: {
          primary: "#000000",
          secondary: "#555555"
        },
        orange: {
          main: "#EA4335",
          contrastText: "#ffffff"
        },
        white: {
          main: "#ffffff",
          contrastText: "#000000"
        },
        black: {
          main: "#444",
          contrastText: "#eee"
        },
        purple: {
          light: '#ba68c8',
          main: '#ab47bc',
          dark: '#9c27b0',
          contrastText: '#fff',
        },
        blue: {
          light: '#64b5f6',
          main: '#42a5f5',
          dark: '#2196f3',
          contrastText: '#fff',
        }
      }),
});


// mui theme settings
// export const themeSettings = (mode) => {
//   const colors = tokens(mode);
//   return {
//     palette: {
//       mode: mode,
//       ...(mode === "dark"
//         ? {
//             // palette values for dark mode
//             primary: {
//               main: colors.primary[500],
//             },
//             secondary: {
//               main: colors.greenAccent[500],
//             },
//             neutral: {
//               dark: colors.grey[700],
//               main: colors.grey[500],
//               light: colors.grey[100],
//             },
//             background: {
//               default: colors.primary[500],
//             },
//           }
//         : {
//             // palette values for light mode
//             primary: {
//               main: colors.primary[100],
//             },
//             secondary: {
//               main: colors.greenAccent[500],
//             },
//             neutral: {
//               dark: colors.grey[700],
//               main: colors.grey[500],
//               light: colors.grey[100],
//             },
//             background: {
//               default: "#fcfcfc",
//             },
//           }),
//     },
//     typography: {
//       fontFamily: ["Roboto", "sans-serif"].join(","),
//       fontSize: 12,
//       h1: {
//         fontFamily: ["Roboto", "sans-serif"].join(","),
//         fontSize: 40,
//       },
//       h2: {
//         fontFamily: ["Roboto", "sans-serif"].join(","),
//         fontSize: 32,
//       },
//       h3: {
//         fontFamily: ["Roboto", "sans-serif"].join(","),
//         fontSize: 24,
//       },
//       h4: {
//         fontFamily: ["Roboto", "sans-serif"].join(","),
//         fontSize: 20,
//       },
//       h5: {
//         fontFamily: ["Roboto", "sans-serif"].join(","),
//         fontSize: 16,
//       },
//       h6: {
//         fontFamily: ["Roboto", "sans-serif"].join(","),
//         fontSize: 14,
//       },
//     },
//   };
// };

export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return createTheme({
    palette: {
      mode,
      primary: {
        main: colors.primary.main,
        contrastText: colors.primary.contrastText
      },
      secondary: {
        main: colors.secondary.main,
        contrastText: colors.secondary.contrastText
      },
      error: {
        main: colors.error.main
      },
      background: {
        default: colors.background.default,
        paper: colors.background.paper
      },
      text: {
        primary: colors.text.primary,
        secondary: colors.text.secondary
      },
      orange: {
        main: colors.orange.main,
        contrastText: colors.orange.contrastText
      },
      white: {
        main: colors.white.main,
        contrastText: colors.white.contrastText
      }
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
      h1: {
        fontSize: 35,
        fontWeight: 500
      },
      h2: {
        fontSize: 30,
        fontWeight: 500
      },
      h3: {
        fontSize: 25,
        fontWeight: 500
      },
      h4: {
        fontSize: 20,
        fontWeight: 400
      },
      h5: {
        fontSize: 18,
        fontWeight: 400
      },
      h6: {
        fontSize: 16,
        fontWeight: 400
      },
      button: {
        textTransform: 'none' // Buttons with normal case text
      }
    }
  });
};


// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};