// CardDownloadPlatform.jsx
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchCardsTotals } from "../../../Redux/ActionCreator";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const CardDownloadPlatform = ({ loadCardTotals, cardTotals = [] }) => {
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    loadCardTotals();
  }, [loadCardTotals]);

  useEffect(() => {
    if (cardTotals && cardTotals.length > 0) {
      const labels = cardTotals.map(item => moment(item.date).format("DD MMM YYYY"));
      const iosData = cardTotals.map(item => item.ios);
      const androidData = cardTotals.map(item => item.android);

      setData({
        labels,
        datasets: [
          {
            label: "Android",
            data: androidData,
            borderColor: "#3b82f6",
            backgroundColor: "rgba(59, 130, 246, 0.5)",
            fill: false,
          },
          {
            label: "iOS",
            data: iosData,
            borderColor: "#34d399",
            backgroundColor: "rgba(52, 211, 153, 0.5)",
            fill: false,
          }
        ],
      });
    }
  }, [cardTotals]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'll',
          displayFormats: {
            day: 'DD MMM YYYY',
          }
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Cards',
        },
        ticks: {
          precision: 0,
        }
      },
    },
  };

  return <Line data={data} options={options} />;
};

const mapStateToProps = (state) => ({
  cardTotals: state.totalCards.cardTotals, // Adjusted to match the new reducer state
});

const mapDispatchToProps = (dispatch) => ({
  loadCardTotals: () => dispatch(fetchCardsTotals()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardDownloadPlatform);
