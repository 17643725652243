import axios from 'axios';
import Chart from 'chart.js/auto';
import {
    REQ_GETUSERS_SUCC,
    REQ_GETUSERS_FAIL,
    FETCH_CARDS_HISTORY_REQUEST,
    FETCH_CARDS_HISTORY_SUCCESS,
    FETCH_CARDS_HISTORY_FAILURE
} from './ActionType'
import {
    makeRequest,
    getAllRequestSuccess,
    getAllRequestFail,
    getEmailDetailsSuccess,
    getEmailDetailsFail,
    
    fetchDDEmailsRequest,
    fetchDDEmailsSuccess,
    fetchDDEmailsFailure,

    uploadSuccess,
    uploadFail,
    getActiveUserSuccess,
    getActiveUserFail,
    updateUserSuccess,
    updateUserFail,
    changePasswordSuccess,
    changePasswordFail,
    fetchCardsRequest,
    fetchCardsSuccess,
    fetchCardsFail,
    deleteUserSuccess,
    deleteUserFail,
    addUserSuccess,
    addUserFail,
    fetchSettingsSuccess,
    fetchSettingsFailure,
    fetchLogsFail,
    fetchLogsRequest,
    fetchLogsSuccess,
    getCardDetailsSuccess,
    getCardDetailsFail,
    deleteCardsSuccess,
    deleteCardsFail,
    editUserSuccess, editUserFailure,
    saveSettingsSuccess, saveSettingsFailure,
    fetchTokenRequest, fetchTokenSuccess, fetchTokenFail,
    // fetchCardsHistoryRequest, fetchCardsHistorySuccess, fetchCardsHistoryFailure,
    uploadExpireCsvRequest,uploadExpireCsvSuccess,uploadExpireCsvFailure,
    

} from './Action';

import { toast } from 'react-toastify';


export const UPLOAD_EXPIRE_CSV_REQUEST = 'UPLOAD_EXPIRE_CSV_REQUEST';
export const UPLOAD_EXPIRE_CSV_SUCCESS = 'UPLOAD_EXPIRE_CSV_SUCCESS';
export const UPLOAD_EXPIRE_CSV_FAILURE = 'UPLOAD_EXPIRE_CSV_FAILURE';

export const uploadCSV = (file) => async (dispatch) => {
    dispatch({ type: UPLOAD_EXPIRE_CSV_REQUEST });
    const formData = new FormData();
    formData.append("upload", file);
    const token = sessionStorage.getItem("jwtToken");
    const url = `${process.env.REACT_APP_API_BASE_URL}emails/new`;
  
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({
        type: UPLOAD_EXPIRE_CSV_SUCCESS,
        payload: response.data,
      });
      console.log(response.data); // Log the response to the console
      return response.data;
    } catch (error) {
      const errorMsg = error.response && error.response.data.message ? error.response.data.message : error.message;
      dispatch({
        type: UPLOAD_EXPIRE_CSV_FAILURE,
        payload: errorMsg,
      });
      throw new Error(errorMsg);
    }
  };
  

export const getEmailList = (query = '', field = 'member_no', filter = '', page = 1, limit = '', sort = 'id', dir = 'asc') => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}emails/new?page=${page}&limit=${limit}&sort=${sort}&dir=${dir}&field=${field}&term=${encodeURIComponent(query)}&filter=${filter}`;

        if (token) {
            axios.get(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            }).then(res => {
                dispatch(getAllRequestSuccess(res.data));
            }).catch(err => {
                dispatch(getAllRequestFail(err.message));
            });
        } else {
            dispatch(getAllRequestFail("Authentication token not found"));
        }
    };
};

export const getEmailDetails = (emailId) => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}email/new/${emailId}`;

        if (token) {
            axios.get(url, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(res => dispatch(getEmailDetailsSuccess(res.data)))
                .catch(err => dispatch(getEmailDetailsFail(err.message)));
        } else {
            dispatch(getEmailDetailsFail("Authentication token not found"));
        }
    };
};

// ---------------------------------------------- email DD -----------------------------------

export const uploadDDEmailCSV = (file) => async (dispatch) => {
    dispatch(makeRequest()); // This action should initiate the request state
    const formData = new FormData();
    formData.append("upload", file);
    const token = sessionStorage.getItem("jwtToken");
    const url = `${process.env.REACT_APP_API_BASE_URL}emails/dd`;

    if (token) {
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });
            toast.success("Upload successful!");
            dispatch(uploadSuccess(response.data));
            return response.data;
        } catch (error) {
            const errorMsg = error.response && error.response.data.message ? error.response.data.message : error.message;
            toast.error("Upload failed: " + errorMsg);
            dispatch(uploadFail(errorMsg));
            throw errorMsg;
        }
    } else {
        const errorMessage = "Authentication token not found";
        toast.error(errorMessage);
        dispatch(uploadFail(errorMessage));
        throw new Error(errorMessage);
    }
};

export const fetchDDEmails = (query = '', field = 'member_no', filter = '', page = 1, limit = 20, sort = 'id', dir = 'DESC') => {
    return (dispatch) => {
        dispatch(fetchDDEmailsRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}emails/dd?page=${page}&limit=${limit}&sort=${sort}&dir=${dir}&field=${field}&term=${encodeURIComponent(query)}&filter=${filter}`;

        if (token) {
            axios.get(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            }).then(response => {
                dispatch(fetchDDEmailsSuccess(response.data));
            }).catch(error => {
                const errorMessage = error.response ? error.response.data.message : error.message;
                dispatch(fetchDDEmailsFailure(errorMessage));
            });
        } else {
            dispatch(fetchDDEmailsFailure("Authentication token not found"));
        }
    };
};




// Add this in your ActionCreator file where other actions are defined
export const getDDEmailDetails = (emailId) => {
    return async (dispatch) => {
        dispatch({ type: 'FETCH_DDEMAIL_DETAILS_REQUEST' });
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}emails/dd/${emailId}`;

        if (token) {
            try {
                const response = await axios.get(url, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                dispatch({ type: 'FETCH_DDEMAIL_DETAILS_SUCCESS', payload: response.data });
            } catch (error) {
                dispatch({ type: 'FETCH_DDEMAIL_DETAILS_FAILURE', payload: error.response ? error.response.data.message : error.message });
            }
        } else {
            dispatch({ type: 'FETCH_DDEMAIL_DETAILS_FAILURE', payload: "Authentication token not found" });
        }
    };
};


// ------------------------------------------- user -----------------------------------------------
export const getActiveUser = () => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}active`;

        if (!token) {
            dispatch(getActiveUserFail("Authentication token not found"));
            return;
        }

        axios.get(url, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => dispatch(getActiveUserSuccess(response.data)))
            .catch(err => dispatch(getActiveUserFail(err.message)));
    };
};

export const updateUser = (userId, userData) => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}${userId}`;

        if (token) {
            axios.post(url, userData, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(() => dispatch(updateUserSuccess(userData)))
                .catch(err => dispatch(updateUserFail(err.message)));
        } else {
            dispatch(updateUserFail("Authentication token not found"));
        }
    }
};

export const changeUserPassword = (userId, passwords) => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}${userId}/changePassword`;

        if (token) {
            axios.post(url, passwords, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(() => dispatch(changePasswordSuccess()))
                .catch(err => dispatch(changePasswordFail(err.message)));
        } else {
            dispatch(changePasswordFail("Authentication token not found"));
        }
    }
};


// ------------------------------------------------------------------------------------------------------------

export const getUserList = (page = 1, limit = 10, sort = 'id', dir = 'asc') => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}users?page=${page}&limit=${limit}&sort=${sort}&dir=${dir}`;

        if (token) {
            axios.get(url, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(res => {
                    if (res.data.success) {
                        dispatch({
                            type: REQ_GETUSERS_SUCC,
                            payload: {
                                users: res.data.results,
                                total: res.data.total
                            }
                        });
                    } else {
                        dispatch({
                            type: REQ_GETUSERS_FAIL,
                            payload: "No users found or empty data set returned."
                        });
                    }
                })
                .catch(err => {
                    // console.error("Error fetching users:", err.response ? err.response.data : err.message);
                    dispatch({
                        type: REQ_GETUSERS_FAIL,
                        payload: err.response ? err.response.data.message : "Failed to fetch users."
                    });
                });
        } else {
            // console.error("Authentication token not found");
            dispatch({
                type: REQ_GETUSERS_FAIL,
                payload: "Authentication token not found"
            });
        }
    };
};

// ---------------------------------------------------------------------------------------------
export const deleteUser = (userId) => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}user/${userId}`;

        if (!token) {
            const error = "Authentication token not found";
            dispatch(deleteUserFail(error));
            return Promise.reject(new Error(error)); // Return a rejected promise to handle errors correctly
        }

        return axios.delete(url, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(() => {
                dispatch(deleteUserSuccess(userId));
            })
            .catch(error => {
                const errorMessage = error.response ? error.response.data.message : error.message;
                dispatch(deleteUserFail(errorMessage));
                return Promise.reject(new Error(errorMessage)); // Ensure that errors are propagated
            });
    };
};


export const addUser = (userData) => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}user`;

        // console.log("URL: ", url); // Log the URL
        //console.log("Posting Data: ", JSON.parse(JSON.stringify(userData)));  // Safely log the data


        if (!token) {
            const error = "Authentication token not found";
            dispatch(addUserFail(error));
            return Promise.reject(new Error(error));
        }

        return axios.post(url, userData, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(response => {
                dispatch(addUserSuccess(response.data));
                //console.log("Response Data: ", response.data); // Log the response data
                return response.data;
            })
            .catch(error => {
                let errorMessage = "Unknown error";
                if (error.response && error.response.data && error.response.data.error) {
                    errorMessage = error.response.data.error.details.map(detail => detail.message).join(", ");
                }
                //console.log("Error Message: ", errorMessage);
                dispatch(addUserFail(errorMessage));
                return Promise.reject(new Error(errorMessage));
            });
    };
};

export const submitUserUpdate = (userData) => {
    const token = sessionStorage.getItem("jwtToken");
    return (dispatch, getState) => {
        return fetch(`${process.env.REACT_APP_API_BASE_URL}user/${userData.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(userData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        const errorMessage = errorData.error.details.map(detail => detail.message).join(", ");
                        dispatch(editUserFailure(errorMessage));
                        throw new Error(errorMessage);
                    });
                }
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    dispatch(editUserSuccess(data));
                    return data;
                } else {
                    const errorMessage = data.message;
                    dispatch(editUserFailure(errorMessage));
                    throw new Error(errorMessage);
                }
            })
            .catch(error => {
                const errorMessage = error.message || "Unknown error";
                //console.log("Error Message: ", errorMessage);
                dispatch(editUserFailure(errorMessage));
                return Promise.reject(new Error(errorMessage));
            });
    };
};


// ======================================== Card List API Call start ===============================================

export const getCardList = (query = '', field = '', filter = '', page = 1, limit = 100, sort = 'id', dir = 'asc') => {
    return (dispatch) => {
        dispatch(fetchCardsRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}cards?page=${page}&limit=${limit}&sort=${sort}&dir=${dir}&field=${field}&term=${encodeURIComponent(query)}&filter=${filter}`;
        if (token) {
            axios.get(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            }).then(res => {
                if (res.data.success) {
                    dispatch(fetchCardsSuccess(res.data));
                    //console.log(res.data);
                } else {
                    dispatch(fetchCardsFail("Failed to fetch cards."));
                }
            }).catch(err => {
                dispatch(fetchCardsFail(err.message));
            });
        } else {
            dispatch(fetchCardsFail("Authentication token not found"));
        }
    };
};

export const getCardDetails = (memberNo) => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}card/${encodeURIComponent(memberNo)}`;
        console.log(url);
        if (token) {
            axios.get(url, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(res => {
                    dispatch(getCardDetailsSuccess(res.data));
                })
                .catch(err => dispatch(getCardDetailsFail(err.message)));
        } else {
            dispatch(getCardDetailsFail("Authentication token not found"));
        }
    };
};

export const FETCH_CARD_LOG_REQUEST = 'FETCH_CARD_LOG_REQUEST';
export const FETCH_CARD_LOG_SUCCESS = 'FETCH_CARD_LOG_SUCCESS';
export const FETCH_CARD_LOG_FAIL = 'FETCH_CARD_LOG_FAIL';

export const fetchCardLog = (memberNo) => {
    return (dispatch) => {
        dispatch({ type: FETCH_CARD_LOG_REQUEST });
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}card/log/${encodeURIComponent(memberNo)}`;
        if (token) {
            axios.get(url, { headers: { 'Authorization': `Bearer ${token}` } })
                .then(res => dispatch({ type: FETCH_CARD_LOG_SUCCESS, payload: res.data }))
                .catch(err => dispatch({ type: FETCH_CARD_LOG_FAIL, payload: err.message }));
        } else {
            dispatch({ type: FETCH_CARD_LOG_FAIL, payload: "Authentication token not found" });
        }
    };
};


// New Action Creator for uploading CSV
export const uploadExpireCSV = (file) => {
    return (dispatch) => {
        dispatch(uploadExpireCsvRequest());

        const formData = new FormData();
        formData.append('upload', file);  

        const token = sessionStorage.getItem("jwtToken");

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        };

        const url = `${process.env.REACT_APP_API_BASE_URL}cards/expire`;

        return axios.post(url, formData, config)
            .then(response => {
                dispatch(uploadExpireCsvSuccess(response.data));
                return response.data;
            })
            .catch(error => {
                const errorMsg = error.response && error.response.data.message ? error.response.data.message : error.message;
                dispatch(uploadExpireCsvFailure(errorMsg));
                throw new Error(errorMsg);
            });
    };
};


export const deleteCard = (platform, memberNo) => {
    return (dispatch) => {
        dispatch(makeRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}card/${platform}/${memberNo}`;

        if (!token) {
            const error = "Authentication token not found";
            dispatch(deleteCardsFail(error));
            return Promise.reject(new Error(error));
        }

        return axios.delete(url, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(() => {
                dispatch(deleteCardsSuccess(platform, memberNo));
            })
            .catch(error => {
                const errorMessage = error.response ? error.response.data.message : error.message;
                dispatch(deleteCardsFail(errorMessage));
                return Promise.reject(new Error(errorMessage));
            });
    };
};




export const FETCH_CARDS_TOTALS_REQUEST = 'FETCH_CARDS_TOTALS_REQUEST';
export const FETCH_CARDS_TOTALS_SUCCESS = 'FETCH_CARDS_TOTALS_SUCCESS';
export const FETCH_CARDS_TOTALS_FAILURE = 'FETCH_CARDS_TOTALS_FAILURE';

export const fetchCardsTotals = () => async (dispatch) => {
  dispatch({ type: FETCH_CARDS_TOTALS_REQUEST });
  const token = sessionStorage.getItem("jwtToken");
  const url = `${process.env.REACT_APP_API_BASE_URL}cards/totals`;
    console.log(url);
  try {
    const response = await axios.get(url, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    if (response.data.success) {
      dispatch({
        type: FETCH_CARDS_TOTALS_SUCCESS,
        payload: response.data.results,
      });
      console.log(response.data.results);
    } else {
      dispatch({
        type: FETCH_CARDS_TOTALS_FAILURE,
        payload: 'Failed to fetch card totals.',
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_CARDS_TOTALS_FAILURE,
      payload: error.message,
    });
  }
};




// ======================================== Card List API Call end ===============================================
// ======================================== Settings Start ===============================================

export const fetchSettings = () => async (dispatch) => {
    const token = sessionStorage.getItem("jwtToken");
    if (!token) {
        dispatch(fetchSettingsFailure("Authentication token not found"));
        return;
    }
    const headers = { 'Authorization': `Bearer ${token}` };
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}settings`, { headers });
        // console.log("Settings fetched:", response.data);
        dispatch(fetchSettingsSuccess(response.data.results));
    } catch (error) {
        //console.error('Fetch settings failed:', error);
        dispatch(fetchSettingsFailure(error.response ? error.response.data : error.message));
    }
};

export const saveSettings = (newContent, subject) => async (dispatch) => {
    const token = sessionStorage.getItem("jwtToken");
    if (!token) {
        dispatch(saveSettingsFailure("Authentication token not found"));
        toast.error("Authentication token not found");
        return;
    }
    const headers = { 'Authorization': `Bearer ${token}` };
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}settings/emailnew`,
            { em_newhtml: newContent, mg_newsubject: subject }, // Ensure this matches your backend expectation
            { headers }
        );
        dispatch(saveSettingsSuccess(response.data));
        toast.success("Settings saved successfully!");
    } catch (error) {
        //console.error('Save settings failed:', error);
        dispatch(saveSettingsFailure(error.response ? error.response.data : error.message));
        toast.error("Failed to save settings");
    }
};

export const updateDDNotice = (content, subject) => async (dispatch) => {
    const token = sessionStorage.getItem("jwtToken"); // Assuming you handle authentication
    if (!token) {
        dispatch(saveSettingsFailure("Authentication token not found"));
        toast.error("Authentication token not found"); // Handling error
        return;
    }
    const headers = { 'Authorization': `Bearer ${token}` };
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}settings/emaildd`,
            { em_ddhtml: content, mg_ddsubject: subject },
            { headers }
        );
        dispatch(saveSettingsSuccess(response.data)); // Assuming you have this action
        toast.success("Settings saved successfully!");
    } catch (error) {
        //console.error('Save settings failed:', error);
        dispatch(saveSettingsFailure(error.toString())); // Handling error
        toast.error("Failed to save settings");
    }
};




export const fetchToken = () => async (dispatch) => {
    dispatch(fetchTokenRequest());
    const token = sessionStorage.getItem("jwtToken");
    if (!token) {
        dispatch(fetchTokenFail("Authentication token not found"));
        toast.error("Authentication token not found"); // Using toast for better user notification
        return;
    }

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}settings/webservice`, {}, { headers });
        if (response.status === 200) {
            dispatch(fetchTokenSuccess(response.data.token));
            //console.log("Token fetched:", response.data.token);
            toast.success("Token fetched successfully!");
        } else {
            dispatch(fetchTokenFail(`Failed to fetch token: ${response.statusText}`));
            toast.error(`Failed to fetch token: ${response.statusText}`);
        }
    } catch (error) {
        dispatch(fetchTokenFail(`Error fetching token: ${error.message}`));
        toast.error(`Error fetching token: ${error.message}`);
    }
};


// ======================================== Setting end ===============================================
// ======================================== Log Start ===============================================
export const fetchLogs = (term = '', level = '', filter = '', page = 1, limit = 20, sort = 'id', dir = 'asc') => {
    return (dispatch) => {
        dispatch(fetchLogsRequest());
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}log?page=${page}&limit=${limit}&sort=${sort}&dir=${dir}&level=${encodeURIComponent(level)}&filter=${encodeURIComponent(filter)}&term=${encodeURIComponent(term)}`;
        //console.log("Sending request to:", url);
        if (token) {
            axios.get(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            }).then(res => {
                if (res.data.success) {
                    dispatch(fetchLogsSuccess({ results: res.data.results, total: res.data.total }));
                } else {
                    dispatch(fetchLogsFail("Failed to fetch logs."));
                }
            }).catch(err => {
                dispatch(fetchLogsFail(err.message));
            });
        } else {
            dispatch(fetchLogsFail("Authentication token not found"));
        }
    };
};


export const deleteAllLogs = () => {
    return (dispatch) => {
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}log`;
        if (token) {
            axios.delete(url, {
                headers: { 'Authorization': `Bearer ${token}` }
            }).then(res => {
                dispatch(fetchLogs()); // Refresh the logs after deletion
            }).catch(err => {
                //console.error("Error deleting log:", err.message);
                // Handle delete error (maybe dispatch a fail action or show a notification)
            });
        } else {
            //console.error("Authentication token not found");
            // Handle no token found (show error or redirect to login)
        }
    };
};



// ======================================== Log end ===============================================

export const fetchCardsHistory = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_CARDS_HISTORY_REQUEST });
        const token = sessionStorage.getItem("jwtToken");
        const url = `${process.env.REACT_APP_API_BASE_URL}cards/totals`;

        axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.data.success) {
                dispatch({
                    type: FETCH_CARDS_HISTORY_SUCCESS,
                    payload: response.data.current,
                });
            } else {
                dispatch({
                    type: FETCH_CARDS_HISTORY_FAILURE,
                    payload: 'Data fetch unsuccessful',
                });
            }
        }).catch(error => {
            dispatch({
                type: FETCH_CARDS_HISTORY_FAILURE,
                payload: error.toString(),
            });
        });
    };
};
