import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { styled } from "@mui/material/styles";
import { getStatusStyle } from "../StatusStyle";

const StatusPill = styled(Box)(({ status, theme }) => ({
  backgroundColor: getStatusStyle(status, theme),
  color: theme.palette.common.white,
  borderRadius: "20px",
  padding: "0.25em 1.75em",
  display: "inline-block",
  fontSize: "0.75em",
}));


const EmailNewInfo = ({ open, handleClose, selectedEmail }) => {
  const getStatusColor = () => {
    if (selectedEmail && selectedEmail.status) {
      const statusKey =
        selectedEmail.status.toLowerCase() === "failed" &&
        selectedEmail.severity
          ? `${selectedEmail.status} ${selectedEmail.severity}`
          : selectedEmail.status;
      return getStatusStyle(statusKey.trim().toLowerCase());
    }
    return "#9e9e9e";
  };

  const getEmailStatus = (email) => {
    if (email.status === "failed") {
      if (email.severity === "permanent") {
        return "Failed permanent";
      } else if (email.severity === "temporary") {
        return "Failed temporary";
      }
      return "Failed";
    }
    return email.status;
  };
  const renderListItemStatus = (primary, secondary) => {
    if (secondary) {
      let primaryText = secondary;
      let secondaryText = null;
      if (primary === "Message" && secondary.includes("#")) {
        const parts = secondary.split("#");
        primaryText = parts[0];
        secondaryText = parts[1] ? parts[1] : null;
      }

      return (
        <ListItem sx={{ py: 0.5 }}>
          <ListItemText
            primary={
              <Typography variant="subtitle2" sx={{ fontSize: '0.8em', fontWeight: '700', textTransform: 'uppercase', color: "#000" }}>
                {primary}
              </Typography>
            }
            secondary={
              <>
                <Typography
                  // sx={{
                  //   color: getStatusColor(),
                  //   textTransform: "capitalize",
                  // }}
                >
                  {primaryText}
                </Typography>
                {secondaryText && (
                  <Typography
                    sx={{
                      color: getStatusColor(),
                      textTransform: "capitalize",
                    }}
                  >
                    {secondaryText}
                  </Typography>
                )}
              </>
            }
          />
        </ListItem>
      );
    }
    return null;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{
          // backgroundColor: getStatusColor(),
          backgroundColor: '#444',
          color: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Added for spacing between title and status
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailIcon />
          <Typography ml={1} variant="h6">Email details</Typography>
        </Box>
        {selectedEmail && (
          <StatusPill status={
            selectedEmail.status === "failed"
              ? `${selectedEmail.status} ${selectedEmail.severity}`
              : selectedEmail.status
          }>
            {getEmailStatus(selectedEmail).toUpperCase()}
          </StatusPill>
        )}
      </DialogTitle>
      <DialogContent>
        {selectedEmail && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <List>
                <ListItem sx={{ py: 0.5 }}>
                  <ListItemText
                    primary={<Typography variant="subtitle2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Member No</Typography>}
                    secondary={selectedEmail.member_no}
                  />
                </ListItem>
                <ListItem sx={{ py: 0.5 }}>
                  <ListItemText
                    primary={<Typography variant="subtitle2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Name</Typography>}
                    secondary={`${selectedEmail.forename} ${selectedEmail.surname}`}
                  />
                </ListItem>
                <ListItem sx={{ py: 0.5 }}>
                  <ListItemText
                    primary={<Typography variant="subtitle2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Email</Typography>}
                    secondary={selectedEmail.email}
                  />
                </ListItem>
                <ListItem sx={{ py: 0.5 }}>
                  <ListItemText
                    primary={<Typography variant="subtitle2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Type</Typography>}
                    secondary={selectedEmail.type}
                  />
                </ListItem>
                <ListItem sx={{ py: 0.5 }}>
                  <ListItemText
                    primary={<Typography variant="subtitle2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Batch No</Typography>}
                    secondary={selectedEmail.batch_no}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              <List>
                {/* {renderListItemStatus("Status", selectedEmail.status)} */}
                {renderListItemStatus("Severity", selectedEmail.severity)}
                <ListItem sx={{ py: 0.5 }}>
                  <ListItemText
                    primary={<Typography variant="subtitle2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Created On</Typography>}
                    secondary={selectedEmail.createdon}
                  />
                </ListItem>
                <ListItem sx={{ py: 0.5 }}>
                  <ListItemText
                    primary={<Typography variant="subtitle2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Sent On</Typography>}
                    secondary={selectedEmail.senton}
                  />
                </ListItem>
                <ListItem sx={{ py: 0.5 }}>
                  <ListItemText
                    primary={<Typography variant="subtitle2" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Updated On</Typography>}
                    secondary={selectedEmail.updatedon}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              {renderListItemStatus("Message", selectedEmail.message)}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailNewInfo;
