import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { styled } from "@mui/material/styles";

const StatusPill = styled(Box)(({ status, theme }) => ({
  backgroundColor: getStatusStyle(status, theme),
  color: theme.palette.common.white,
  borderRadius: "20px",
  padding: "0.25em 1.75em",
  display: "inline-block",
  fontSize: "0.75em",
}));

const getStatusStyle = (status) => {
  switch (status) {
    case "active":
      return "#00b72a";
    case "requested":
      return "#1976D2";
    case "inactive":
      return "#757575";
    default:
      return "#9E9E9E";
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const CardInfo = ({ open, handleClose, selectedMemberNo }) => {
  const [tabValue, setTabValue] = useState(0);
  const [logData, setLogData] = useState([]);
  const cardDetails = useSelector(state => state.cards.cardDetails);

  useEffect(() => {
    if (tabValue === 1 && selectedMemberNo) {
        const token = sessionStorage.getItem("jwtToken");
        if (token) {
            const config = {
                headers: { 'Authorization': `Bearer ${token}` }
            };
            axios.get(`${process.env.REACT_APP_API_BASE_URL}card/log/${encodeURIComponent(selectedMemberNo)}`, config)
                .then(response => {
                    if (response.data.success && response.data.results.length > 0) {
                        setLogData(response.data.results);  // Ensure this is the array of logs
                    } else {
                        console.log('No logs found for this member.');
                        setLogData([]);  // Handle case where no logs are available
                    }
                })
                .catch(error => {
                    console.error('Error fetching card logs:', error);
                    setLogData([]);  // Clear data on error
                });
        } else {
            console.error('Authentication token not found');
        }
    }
}, [tabValue, selectedMemberNo]); 

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const CustomTableCell = styled(TableCell)(({ theme }) => ({
    padding: "10px",
    textTransform: "uppercase",
    fontSize: "0.8em",
    color: "#000",
    fontWeight: "700"
  }));

  const CustomTableCellInner = styled(TableCell)(({ theme }) => ({
    padding: "10px",
    fontSize: "0.8em"
  }));

  return (
    <Dialog open={open} onClose={handleClose}
    aria-labelledby="form-dialog-title"
    fullWidth
    maxWidth="sm">
      <DialogTitle
        id="form-dialog-title"
        sx={{
          backgroundColor: '#444',
          color: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between", // Added for spacing between title and status
        }}
      >
        <Box  sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",}}>
          <CreditCardIcon />
          <Typography ml={1}>Membership Card Detail</Typography>
        </Box>
        {cardDetails && <StatusPill status={cardDetails.status}>{cardDetails.status.toUpperCase()}</StatusPill>}
      </DialogTitle>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Details" />
        <Tab label="Log" />
      </Tabs>
      <DialogContent>
        <TabPanel value={tabValue} index={0}>
        {cardDetails && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="NAME"
                    secondary={`${cardDetails.forename} ${cardDetails.surname}`}
                    primaryTypographyProps={{
                      sx: { fontSize: "0.8em", fontWeight: "700" },
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="PLATFORM"
                    secondary={cardDetails.platform}
                    primaryTypographyProps={{
                      sx: { fontSize: "0.8em", fontWeight: "700" },
                    }}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="SUBSCRIPTION"
                    secondary={cardDetails.subscription}
                    primaryTypographyProps={{
                      sx: { fontSize: "0.8em", fontWeight: "700" },
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="ISSUE ID"
                    secondary={cardDetails.issue_id}
                    primaryTypographyProps={{
                      sx: { fontSize: "0.8em", fontWeight: "700" },
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="REGION"
                    secondary={cardDetails.region}
                    primaryTypographyProps={{
                      sx: { fontSize: "0.8em", fontWeight: "700" },
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={6}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="MEMBER NUMBER"
                    secondary={cardDetails.member_no}
                    primaryTypographyProps={{
                      sx: { fontSize: "0.8em", fontWeight: "700" },
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="ISSUE NUMBER"
                    secondary={cardDetails.issue_no}
                    primaryTypographyProps={{
                      sx: { fontSize: "0.8em", fontWeight: "700" },
                    }}
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    primary="CREATED ON"
                    secondary={cardDetails.createdon}
                    primaryTypographyProps={{
                      sx: { fontSize: "0.8em", fontWeight: "700" },
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="UPDATED ON"
                    secondary={cardDetails.updatedon}
                    primaryTypographyProps={{
                      sx: { fontSize: "0.8em", fontWeight: "700" },
                    }}
                  />
                </ListItem>
                <ListItem>
                  {cardDetails.branch && (
                    <ListItemText
                      primary="BRANCH"
                      secondary={cardDetails.branch}
                      primaryTypographyProps={{
                        sx: { fontSize: "0.8em", fontWeight: "700" },
                      }}
                    />
                  )}
                </ListItem>
              </List>
            </Grid>
          </Grid>
        )}
        {!cardDetails && <Typography>No details available</Typography>}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box sx={{ maxHeight: '352px', overflow: 'auto' }}>
            {logData.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>Issue No</CustomTableCell>
                            <CustomTableCell>Platform</CustomTableCell>
                            <CustomTableCell>Status</CustomTableCell>
                            <CustomTableCell>Created On</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logData.map((log, index) => (
                            <TableRow key={index}>
                                <CustomTableCellInner>{log.issue_no}</CustomTableCellInner>
                                <CustomTableCellInner>{log.platform}</CustomTableCellInner>
                                <CustomTableCellInner>{log.status}</CustomTableCellInner>
                                <CustomTableCellInner>{log.createdon}</CustomTableCellInner>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Typography>No log data available for this member.</Typography>
            )}
          </Box>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CardInfo;
