import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCardsHistory } from '../../../Redux/ActionCreator';
import { Grid, Card, CardContent, Typography, Box, Avatar } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const PlatformDownloadCount = () => {
  const dispatch = useDispatch();
  const { totalCount, androidCount, iosCount } = useSelector(state => state.cardHistory);

  useEffect(() => {
    dispatch(fetchCardsHistory());
  }, [dispatch]);

  return (
    <Box sx={{ p: 1, backgroundColor: "#f1f3f4" }}>
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: "0",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Avatar sx={{ bgcolor: "#ffe0b2", width: 50, height: 50 }}>
              <CreditCardIcon sx={{ color: "#ff6f00" }} />
            </Avatar>
            <Box>
              <Typography
                variant="h5"
                component="div"
                sx={{ mt: 1, fontWeight: "bold" }}
              >
                Active Membership Cards
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 1.5 }}
              >
                Current number of active cards
              </Typography>
            </Box>
          </Box>
          <Box>
            <Grid container sx={{ mt: 1, justifyContent: "center" }}>
            <Grid item xs={12} md={3}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#5f6368", fontWeight: "700" }}
                  gutterBottom
                >
                  Total
                </Typography>
                <Typography variant="h3" fontSize="1em" color="#ea4335" fontWeight='bold'>
                  {totalCount}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#5f6368", fontWeight: "700" }}
                  gutterBottom
                >
                  Android
                </Typography>
                <Typography variant="h3" fontSize="1em" color="#ea4335" fontWeight='bold'>
                  {androidCount}
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#5f6368", fontWeight: "700" }}
                  gutterBottom
                >
                  Apple
                </Typography>
                <Typography variant="h3" fontSize="1em" color="#ea4335" fontWeight='bold'>
                  {iosCount}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PlatformDownloadCount;
