import { Box } from "@mui/material";
import Sidebar from "../../global/Sidebar";
import NewJoinersSettings from "./NewJoinerSettings";
const settings = () => {
  return (
    <Box sx={{ display: "flex" }} mt={2}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
        {/* <Typography variant="h3" component="h2" mb={2} sx={{fontWeight:'300'}}>Settings</Typography> */}
        <NewJoinersSettings />
      </Box>
    </Box>
  )
}

export default settings
