import {
  Box,
  TextField,
  IconButton,
  FormControl,
  // InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "8px 26px 8px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const EmailNewSearch = ({
  searchQuery,
  setSearchQuery,
  handleSearch,
  setField,
  field,
}) => {
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(); // Trigger search when Enter key is pressed
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        gap: 1,
        p: 1,
      }}
    >
      <FormControl size="small" variant="standard" sx={{ minWidth: 130 }}>
        <Select
          value={field}
          label="Field"
          onChange={(e) => setField(e.target.value)}
          input={<BootstrapInput />}
        >
          <MenuItem value="member_no">Member No</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="surname">Surname</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        size="small"
        placeholder="Search by member no, email, or surname"
        value={searchQuery}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        autoFocus
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleSearch} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          ".MuiOutlinedInput-root": {
            borderRadius: "4px",
            paddingRight: 0, // Adjust padding to align end adornment better
          },
        }}
      />
    </Box>
  );
};

export default EmailNewSearch;
