import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../../../Redux/ActionCreator"; // Adjust the import path as needed
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableSortLabel,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  Button,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";

import AddUserForm from "./AddUserForm";
// import UserInfo from "./UserInfo";
import DeleteUserButton from "./DeleteUserButton";
import EditUserForm from "./EditUserForm";
import RefreshIcon from "@mui/icons-material/Refresh";

const UserListTable = () => {
  const [openAddUser, setOpenAddUser] = useState(false);
  // const [openUserInfo, setOpenUserInfo] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [editableUser, setEditableUser] = useState(null);
  // const [selectedUser, setSelectedUser] = useState(null);

  const dispatch = useDispatch();
  const { users, total, isLoading, errorMessage } = useSelector(
    (state) => state.users
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");

  const [refreshFlag, setRefreshFlag] = useState(false);

  useEffect(() => {
    dispatch(getUserList(currentPage + 1, rowsPerPage, orderBy, order));
  }, [dispatch, currentPage, rowsPerPage, orderBy, order]);

  const handleOpenAddUser = () => setOpenAddUser(true);
  const handleCloseAddUser = () => setOpenAddUser(false);

  // const handleOpenUserInfo = (user) => {
  //   setSelectedUser(user);
  //   setOpenUserInfo(true);
  // };
  // const handleCloseUserInfo = () => setOpenUserInfo(false);

  const handleChangePage = (event, newPage) => setCurrentPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleRequestSort = (event, property) => {
    if (property === "actions") return;
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property === "name" ? "forename" : property);
    dispatch(
      getUserList(
        currentPage + 1,
        rowsPerPage,
        property === "name" ? "forename" : property,
        isAsc ? "desc" : "asc"
      )
    );
  };

  const handleEdit = (user) => {
    setEditableUser(user);
    setOpenEditForm(true);
  };

  const handleCloseEditForm = () => {
    setOpenEditForm(false);
    setEditableUser(null);
  };

  const handleRefresh = useCallback(() => {
    dispatch(getUserList(currentPage + 1, rowsPerPage, orderBy, order));
  }, [dispatch, currentPage, rowsPerPage, orderBy, order]);

  useEffect(() => {
    if (refreshFlag) {
      handleRefresh();
      setRefreshFlag(false);
    }
  }, [refreshFlag, handleRefresh]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#000",
    color: theme.palette.common.white,
    fontWeight: "400",
    fontSize: "0.75em",
    "& .MuiTableSortLabel-root": {
      "&:hover": {
        color: "#ccc",
      },
      "&.Mui-active": {
        color: "#ccc",
        "& .MuiTableSortLabel-icon": {
          color: "#ddd",
        },
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  }));

  const TableCellInner = styled(TableCell)(({ theme }) => ({
    padding: "5px 10px",
    fontSize: "0.8em",
  }));

  return (
    <div>
      <Stack
        direction="row"
        spacing={2}
        mt={2}
        mb={3}
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#20a1a1", color: "#ffffff" }}
            onClick={handleOpenAddUser}
          >
            Add User
          </Button>
          <AddUserForm
            open={openAddUser}
            handleClose={handleCloseAddUser}
            onUserAdded={() => setRefreshFlag(true)}
            onCancel={() => setRefreshFlag(true)}
          />
        </Box>
      </Stack>
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {[
                  { id: "id", label: "ID", sortable: true },
                  { id: "username", label: "USERNAME", sortable: true },
                  { id: "name", label: "NAME", sortable: true }, // Sorts by 'forename'
                  { id: "email", label: "EMAIL", sortable: true },
                  { id: "user_type", label: "USER TYPE", sortable: true },
                  { id: "blocked", label: "BLOCKED", sortable: true },
                  { id: "createdon", label: "CREATED ON", sortable: true },
                  { id: "actions", label: "ACTIONS", sortable: false }, // Not sortable
                ].map((headCell) => (
                  <StyledTableCell
                    key={headCell.id}
                    sortDirection={
                      headCell.sortable && orderBy === headCell.id
                        ? order
                        : false
                    }
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={(event) =>
                          handleRequestSort(event, headCell.id)
                        }
                      >
                        {headCell.label}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : errorMessage ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {errorMessage}
                  </TableCell>
                </TableRow>
              ) : users.length > 0 ? (
                users.map((user) => (
                  <StyledTableRow key={user.id}>
                    <TableCellInner>{user.id}</TableCellInner>
                    <TableCellInner>{user.username}</TableCellInner>
                    <TableCellInner>{`${user.forename} ${user.surname}`}</TableCellInner>
                    <TableCellInner>{user.email}</TableCellInner>
                    <TableCellInner>{user.user_type}</TableCellInner>
                    <TableCellInner>
                      {user.blocked === 0 ? "No" : "Yes"}
                    </TableCellInner>
                    <TableCellInner>{user.createdon}</TableCellInner>
                    <TableCellInner>
                      {/* <IconButton
                        onClick={() => handleOpenUserInfo(user)} // Correct function to open user info
                        sx={{ color: "#444" }}
                      >
                        <InfoIcon />
                      </IconButton> */}
                      <IconButton
                        onClick={() => handleEdit(user)}
                        sx={{ color: "rgb(33, 150, 243)" }}
                      >
                        <EditIcon />
                      </IconButton>
                      <DeleteUserButton
                        user={user}
                        getUserList={getUserList}
                        currentPage={currentPage}
                        rowsPerPage={rowsPerPage}
                        orderBy={orderBy}
                        order={order}
                      />
                    </TableCellInner>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No users found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            borderTop: "1px solid #eee",
            backgroundColor: " #dfdfdf",
          }}
        >
          <IconButton
            onClick={() => setRefreshFlag(true)}
            aria-label="refresh"
            color="#aaa"
            sx={{ marginRight: 2 }}
          >
            <RefreshIcon />
          </IconButton>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from} – ${to} of ${count}`}
            sx={{
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-input': {
                fontSize: '0.8rem'
              },
            }} 
          />
        </Box>
      </Paper>
      {/* <UserInfo
        open={openUserInfo}
        selectedUser={selectedUser}
        handleClose={handleCloseUserInfo}
      /> */}
      <EditUserForm
        open={openEditForm}
        handleClose={handleCloseEditForm}
        user={editableUser}
        triggerRefresh={() => setRefreshFlag(true)}
        isEditing={true}
        onCancel={() => setRefreshFlag(true)}
      />
    </div>
  );
};

export default UserListTable;
