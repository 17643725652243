import { Box } from "@mui/material";
import Sidebar from "../../global/Sidebar";
import EmailTabs from "./EmailTabs";

const Email = () => {
  return (
    <Box sx={{ display: "flex" }} mt={2}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
        <EmailTabs />
      </Box>
    </Box>
  )
}

export default Email