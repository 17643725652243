import { useState, useRef, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import { connect } from "react-redux";
import { fetchSettings, updateDDNotice } from "../../../Redux/ActionCreator";

const EditorContainer = styled(Box)(({ theme }) => ({
  // marginTop: theme.spacing(2),
  background: "#fff",
  borderRadius: theme.shape.borderRadius,
}));

const SaveButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: "#EA4335",
}));

const DDNotice = (props) => {
  const { em_ddhtml, mg_ddsubject, fetchSettings } = props;
  const [editableSubject, setEditableSubject] = useState(em_ddhtml);
  const [subject, setSubject] = useState(mg_ddsubject);
  const editorRef = useRef(null);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    setEditableSubject(em_ddhtml);
    setSubject(mg_ddsubject);
  }, [em_ddhtml, mg_ddsubject]);

  // const handleSaveText = () => {
  //   if (editorRef.current) {
  //     const content = editorRef.current.getContent();
  //     console.log("Saved content: ", content);
  //     console.log("Saved subject: ", subject);
  //   }
  // };
  const handleSaveText = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      const currentSubject = subject; // Renamed local variable
      // console.log("Saving content:", content, "Subject:", currentSubject);
      props.updateDDNotice(content, currentSubject); // Use the renamed variable
    }
  };
  

  return (
    <EditorContainer>
      <TextField
        fullWidth
        label="Subject"
        variant="outlined"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        margin="normal"
      />
      <Editor
        apiKey="oawwl0p0jrlgvb4t95b5cq9xyfdazp02yx2mf7myv5e61hfr"
        initialValue={editableSubject}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onEditorChange={(content) => setEditableSubject(content)}
        init={{
          height: 450,
          menubar: false,
          plugins: [
            "link", "image", "media", "table", "code", "lists", "preview"
          ],
          toolbar: "undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code",
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <SaveButton
        variant="contained"
        startIcon={<SaveIcon />}
        onClick={handleSaveText}
      >
        Save Changes
      </SaveButton>
    </EditorContainer>
  );
};

const mapStateToProps = (state) => ({
  em_ddhtml: state.settings.em_ddhtml,
  mg_ddsubject: state.settings.mg_ddsubject,
});

// Modify mapDispatchToProps in your component file

const mapDispatchToProps = {
  fetchSettings,
  updateDDNotice, // The new action you've created
};


export default connect(mapStateToProps, mapDispatchToProps)(DDNotice);
