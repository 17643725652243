// Action.js

import {
    MAKE_REQ,
    REQ_GETALL_SUCC,
    REQ_GETALL_FAIL,
    REQ_GETEMAILDETAILS_SUCC,
    REQ_GETEMAILDETAILS_FAIL,

    FETCH_DDEMAILS_REQUEST,
    FETCH_DDEMAILS_SUCCESS,
    FETCH_DDEMAILS_FAILURE,

    UPLOAD_SUCCESS,
    UPLOAD_FAIL,
    GET_ACTIVE_USER_SUCC,
    GET_ACTIVE_USER_FAIL,
    UPDATE_USER_SUCC,
    UPDATE_USER_FAIL,
    CHANGE_PASSWORD_SUCC,
    CHANGE_PASSWORD_FAIL,
    ADD_USER_SUCCESS,
    ADD_USER_FAIL,

    EDIT_USER_SUCCESS,
    EDIT_USER_FAILURE,

    FETCH_USER_DETAILS_SUCCESS,
    FETCH_USER_DETAILS_FAIL,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    UPDATE_EDIT_USER_SUCCESS,
    UPDATE_EDIT_USER_FAILURE,
    UPDATE_EDIT_USER_ERROR,
    FETCH_CARDS_REQUEST,
    FETCH_CARDS_SUCCESS,
    FETCH_CARDS_FAIL,

    GET_CARD_DETAILS_SUCCESS,
    GET_CARD_DETAILS_FAIL,
    DELETE_CARD_SUCCESS,
    DELETE_CARD_FAIL,

    FETCH_SETTINGS_SUCCESS,
    FETCH_SETTINGS_FAILURE,

    FETCH_TOKEN_REQUEST,
    FETCH_TOKEN_SUCCESS,
    FETCH_TOKEN_FAILURE,

    FETCH_LOGS_FAIL,
    FETCH_LOGS_REQUEST,
    FETCH_LOGS_SUCCESS,

    SAVE_SETTINGS_SUCCESS,
    SAVE_SETTINGS_FAILURE,

    FETCH_CARDS_HISTORY_SUCCESS,
    FETCH_CARDS_HISTORY_FAILURE,
    FETCH_CARDS_HISTORY_REQUEST,

    UPLOAD_EXPIRE_CSV_REQUEST,
    UPLOAD_EXPIRE_CSV_SUCCESS,
    UPLOAD_EXPIRE_CSV_FAILURE,

    UPLOAD_CSV_REQUEST,
    UPLOAD_CSV_SUCCESS,
    UPLOAD_CSV_FAILURE



} from "./ActionType";

export const makeRequest = () => ({ type: MAKE_REQ });

export const getAllRequestSuccess = (data) => ({ type: REQ_GETALL_SUCC, payload: data });
export const getAllRequestFail = (error) => ({ type: REQ_GETALL_FAIL, payload: error });

export const getEmailDetailsSuccess = (data) => ({ type: REQ_GETEMAILDETAILS_SUCC, payload: data });
export const getEmailDetailsFail = (error) => ({ type: REQ_GETEMAILDETAILS_FAIL, payload: error });

export const fetchDDEmailsRequest = () => ({ type: FETCH_DDEMAILS_REQUEST });
export const fetchDDEmailsSuccess = (data) => ({ type: FETCH_DDEMAILS_SUCCESS, payload: data });
export const fetchDDEmailsFailure = (error) => ({ type: FETCH_DDEMAILS_FAILURE, payload: error });

export const uploadSuccess = (data) => ({ type: UPLOAD_SUCCESS, payload: data });
export const uploadFail = (error) => ({ type: UPLOAD_FAIL, payload: error });

export const getActiveUserSuccess = (data) => ({ type: GET_ACTIVE_USER_SUCC, payload: data });
export const getActiveUserFail = (error) => ({ type: GET_ACTIVE_USER_FAIL, payload: error });

export const updateUserSuccess = (data) => ({ type: UPDATE_USER_SUCC, payload: data });
export const updateUserFail = (error) => ({ type: UPDATE_USER_FAIL, payload: error });

export const changePasswordSuccess = (data) => ({ type: CHANGE_PASSWORD_SUCC, payload: data });
export const changePasswordFail = (error) => ({ type: CHANGE_PASSWORD_FAIL, payload: error });

export const addUserSuccess = (data) => ({ type: ADD_USER_SUCCESS, payload: data });
export const addUserFail = (error) => ({ type: ADD_USER_FAIL, payload: error });

export const editUserSuccess = (data) => ({ type: EDIT_USER_SUCCESS, payload: data });
export const editUserFailure = (error) => ({ type: EDIT_USER_FAILURE, payload: error });
// export const editUserError = (error) => ({ type: EDIT_USER_ERROR, payload: error });


export const fetchUserDetailsSuccess = (data) => ({ type: FETCH_USER_DETAILS_SUCCESS, payload: data });
export const fetchUserDetailsFail = (error) => ({ type: FETCH_USER_DETAILS_FAIL, payload: error });

export const deleteUserSuccess = (data) => ({ type: DELETE_USER_SUCCESS, payload: data });
export const deleteUserFail = (data) => ({ type: DELETE_USER_FAIL, payload: data });

export const updateEditUserSuccess = (data) => ({ type: UPDATE_EDIT_USER_SUCCESS, payload: data });
export const updateEditUserFailure = (error) => ({ type: UPDATE_EDIT_USER_FAILURE, payload: error });
export const updateEditUserError = (error) => ({ type: UPDATE_EDIT_USER_ERROR, payload: error });

export const fetchCardsRequest = () => ({ type: FETCH_CARDS_REQUEST });
export const fetchCardsSuccess = (data) => ({ type: FETCH_CARDS_SUCCESS, payload: data });
export const fetchCardsFail = (error) => ({ type: FETCH_CARDS_FAIL, payload: error });

export const getCardDetailsSuccess = (data) => ({ type: GET_CARD_DETAILS_SUCCESS, payload: data });
export const getCardDetailsFail = (error) => ({ type: GET_CARD_DETAILS_FAIL, payload: error });

export const deleteCardsSuccess = (data) => ({ type: DELETE_CARD_SUCCESS, payload: data });
export const deleteCardsFail = (error) => ({ type: DELETE_CARD_FAIL, payload: error });

export const fetchSettingsSuccess = (data) => ({ type: FETCH_SETTINGS_SUCCESS, payload: data });
export const fetchSettingsFailure = (error) => ({ type: FETCH_SETTINGS_FAILURE, payload: error });

export const fetchTokenRequest = () => ({ type: FETCH_TOKEN_REQUEST });
export const fetchTokenSuccess = (data) => ({ type: FETCH_TOKEN_SUCCESS, payload: data });
export const fetchTokenFail = (error) => ({ type: FETCH_TOKEN_FAILURE, payload: error });

export const fetchLogsRequest = () => ({ type: FETCH_LOGS_REQUEST });
export const fetchLogsSuccess = (data) => ({ type: FETCH_LOGS_SUCCESS, payload: data });
export const fetchLogsFail = (error) => ({ type: FETCH_LOGS_FAIL, payload: error });

export const saveSettingsSuccess = (data) => ({type: SAVE_SETTINGS_SUCCESS,payload: data,});
export const saveSettingsFailure = (error) => ({type: SAVE_SETTINGS_FAILURE,payload: error,});


export const fetchCardsHistoryRequest = () => ({type: FETCH_CARDS_HISTORY_REQUEST});
export const fetchCardsHistorySuccess = data => ({type: FETCH_CARDS_HISTORY_SUCCESS,payload: data});
export const fetchCardsHistoryFailure = error => ({type: FETCH_CARDS_HISTORY_FAILURE,payload: error});

export const uploadExpireCsvRequest = () => ({type: UPLOAD_EXPIRE_CSV_REQUEST});
export const uploadExpireCsvSuccess = data => ({type: UPLOAD_EXPIRE_CSV_SUCCESS,payload: data});
export const uploadExpireCsvFailure = error => ({type: UPLOAD_EXPIRE_CSV_FAILURE,payload: error});

export const uploadCsvRequest = () => ({type: UPLOAD_CSV_REQUEST});
export const uploadCsvSuccess = data => ({type: UPLOAD_CSV_SUCCESS,payload: data});
export const uploadCsvFailure = error => ({type: UPLOAD_CSV_FAILURE,payload: error});


