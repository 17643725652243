import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import EmailNewTable from "./EmailNew/EmailNewTable"; // Adjust path as necessary
import DDEmailTable from "./EmailDD/DDEmailTable"; // Adjust path as necessary, assuming DDEmailTable exists

const EmailTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="email tabs"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#ccc",
          },
          "& button": {
            borderRadius: 0,
            margin: "0 8px 0 0",
            padding: "12px 16px",
            fontSize: "0.875rem",
            fontWeight: 600,
            letterSpacing: "0.05rem",
            transition: "background-color 0.3s, transform 0.2s",
            backgroundColor: "#cccccc",
            "&:hover": {
              backgroundColor: "#f4f4f4",
            },
            "&:focus": {
              backgroundColor: "#dddddd",
              outline: "none",
            },
            "&.Mui-selected": {
              backgroundColor: "#000000",
              color: "#ffffff",
            },
            "&:active": {
              backgroundColor: "#aaaaaa",
            },
          },
        }}
      >
        <Tab label="New members email" />
        <Tab label="DD notification emails" />
      </Tabs>
      {selectedTab === 0 && <EmailNewTable />}
      {selectedTab === 1 && <DDEmailTable />}
    </>
  );
};

export default EmailTabs;
