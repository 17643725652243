import React, { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const DDEmailDownload = () => {
  const [open, setOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = async () => {
    const params = {};
    if (dateFrom)
      params.date_from = dayjs(dateFrom).format("YYYY-MM-DD HH:mm:ss");
    if (dateTo) params.date_to = dayjs(dateTo).format("YYYY-MM-DD HH:mm:ss");

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_BASE_URL}emails/dd/export`,
        method: "GET",
        params: params,
        responseType: "blob", // important for downloading files
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("jwtToken")}`, // Use the token from sessionStorage
        },
      });

      const filename = response.headers["content-disposition"]
        .split("filename=")[1]
        .replace(/"/g, "");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      handleClose();
    } catch (error) {
      console.error("Download error:", error);
      // Handle errors, for example, by showing an error message
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FileDownloadIcon />}
        onClick={handleClickOpen}
        sx={{ backgroundColor: "#20a1a1", color: "#ffffff" }}
      >
        Generate Report
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            backgroundColor: "#444",
            color: "#ffffff",
            display: "flex",
            alignItems: "center",
          }}
        >
          Download Report
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4} mt={1}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="date_from"
                label="Date From"
                type="datetime-local"
                fullWidth
                variant="standard"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                id="date_to"
                label="Date To"
                type="datetime-local"
                fullWidth
                variant="standard"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDownload}>Download</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DDEmailDownload;
