import React, { useEffect, useState } from "react";
import { getActiveUser } from "../../../Redux/ActionCreator";
import { useDispatch, useSelector } from "react-redux";
import EditProfile from "./EditProfile";
import {
  CircularProgress,
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Button,
  Avatar,
  Grid,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { deepOrange } from '@mui/material/colors';

const ActiveUserProfile = () => {
  const dispatch = useDispatch();
  const [openEditForm, setOpenEditForm] = useState(false);
  const [editableUser, setEditableUser] = useState(null);

  useEffect(() => {
    dispatch(getActiveUser());
  }, [dispatch]);

  const activeUser = useSelector((state) => state.activeUser.activeUser);
  const isLoading = useSelector((state) => state.activeUser.isLoading);
  const error = useSelector((state) => state.activeUser.errorMessage);

  const handleEdit = () => {
    setEditableUser(activeUser);
    setOpenEditForm(true);
  };

  const handleCloseEditForm = () => {
    setOpenEditForm(false);
    setEditableUser(null);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">{`Error: ${error}`}</Typography>;
  if (!activeUser) return <Typography>No active user found.</Typography>;

  return (
    <Box sx={{ padding: 3, maxWidth: 600 }}>
      <Card elevation={4}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: deepOrange[400] }}>
              <PersonIcon />
            </Avatar>
          }
          title={activeUser.nickname}
          sx={{
            bgcolor: "orange.main",
            color: "#ffffff",
            display: "flex",
            alignItems: "center",
          }}
        />

        <CardContent>
          <Grid container spacing={2} alignItems="center"></Grid>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <DataField
              label="Full Name"
              value={`${activeUser.forename} ${activeUser.surname}`}
            />
            <DataField label="Username" value={activeUser.username} />
            <DataField label="Email" value={activeUser.email} />
            <DataField label="Role" value={activeUser.user_type} />
            <DataField label="Created On" value={activeUser.createdon} />
          </Grid>
          <Box
            sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "#20a1a1", color: "#ffffff" }}
              onClick={handleEdit}
            >
              Edit Profile
            </Button>
            <EditProfile
              open={openEditForm}
              handleClose={handleCloseEditForm}
              user={editableUser}
              isEditing={true}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const DataField = ({ label, value }) => (
  <Grid item xs={6}>
    <Typography variant="subtitle2" color="text.secondary">
      {label}
    </Typography>
    <Typography variant="body1">{value}</Typography>
  </Grid>
);

export default ActiveUserProfile;
