import {
    FETCH_CARDS_HISTORY_REQUEST,
    FETCH_CARDS_HISTORY_SUCCESS,
    FETCH_CARDS_HISTORY_FAILURE
} from './ActionType';

const initialState = {
    loading: false,
    error: null,
    totalCount: 0,
    androidCount: 0,
    iosCount: 0,
};

const cardHistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CARDS_HISTORY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_CARDS_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                totalCount: action.payload.total,
                androidCount: action.payload.android,
                iosCount: action.payload.ios,
                error: null
            };
        case FETCH_CARDS_HISTORY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default cardHistoryReducer;

