import { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Box,
  Button,
  CssBaseline,
  Typography,
  TextField,
} from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import backgroundImage from "./assets/Card.jpg";
import gmblogo from "./assets/gmb-logo.png"

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`&&& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: '#ff0000',
    fontSize: 11,
    padding: 0,
    marginTop: 3
  }
}));

const Login = () => {
  const [username, usernameupdate] = useState("");
  const [password, passwordupdate] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("jwtToken");
    if (token) {
      navigate("/"); // Redirects to Dashboard if already logged in
    }
  }, [navigate]);

  const ProceedLogin = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("proceed");
      let inputObj = { username: username, password: password };
      fetch(`${process.env.REACT_APP_API_BASE_URL}login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(inputObj),
      })
        .then((res) => res.json())
        .then((resp) => {
          console.log(resp);
          if (resp.success && resp.token) {
            console.log("Token received:", resp.token);
            sessionStorage.setItem("jwtToken", resp.token);
            toast.success("Login Successful!");
            navigate("/");
          } else {
            // Using the message from the response to inform the user
            toast.error(`Login failed: ${resp.message}`);
          }
        })
        .catch((err) => {
          // It's good to handle unexpected errors separately
          toast.error("Login Failed due to: " + err.message);
        });
    }
  };


  const validate = () => {
    let isValid = true;
    setUsernameError(false);
    setPasswordError(false);

    if (!username) {
      setUsernameError(true);
      isValid = false;
    }
    if (!password) {
      setPasswordError(true);
      isValid = false;
    }
    return isValid;
  };


  return (
    <Grid container component="main">
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light" ? t.palette.primary : t.palette.black,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          backgroundColor: (t) =>
            t.palette.mode === "light" ? t.palette.primary : t.palette.black,
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
            height: "calc(100vh - 130px)",
          }}
        >
          <img src={gmblogo} alt="Logo" />
          <Typography sx={{ mt: 2 }} component="h1" variant="h2">Login to GMB Members</Typography>
          <Box component="form" onSubmit={ProceedLogin} sx={{ mt: 1 }}>
            <LightTooltip
              title="Please enter username"
              open={usernameError}
              placement="bottom-start"
            >
              <TextField
                margin="normal"
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => usernameupdate(e.target.value)}
                error
                variant="standard"
              />
            </LightTooltip>
            <LightTooltip
              title="Please enter password"
              open={passwordError}
              placement="bottom-start"
            >
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => passwordupdate(e.target.value)}
                error={passwordError}
                variant="standard"
              />
            </LightTooltip>
            <Button
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 4,
                mb: 2,
                fontSize: 16,
                bgcolor: "#ff6a13",
                "&:hover": {
                  bgcolor: "#f5803c",
                },
              }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
