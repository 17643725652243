import React from "react";
// import { useDispatch } from "react-redux";
// import { getCardList } from "../../../Redux/ActionCreator";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Box,
  IconButton,
  InputBase,
  InputAdornment
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";

const CardSearch = ({ searchQuery, setSearchQuery, handleSearch, field, setField }) => {
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  // const handleFieldChange = (event) => {
  //   setField(event.target.value);
  // };

  // Function to handle key press event
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && searchQuery.length >= 3) {
      handleSearch(); 
    }
  };

  const handleSearchButtonClick = () => {
    if (searchQuery.length >= 3) {
      handleSearch();
    }
  };

  // Custom styled component for select input
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(1),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '8px 26px 8px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));

  return (
    <Box sx={{
      bgcolor: "#fff",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      gap: 1,
      p: 1,
    }}>
       <FormControl variant="outlined" sx={{ minWidth: 140 }}>
        <Select
          value={field}
          onChange={(e) => setField(e.target.value)}
          input={<BootstrapInput />}
        >
          <MenuItem value="member_no">Member No</MenuItem>
          <MenuItem value="surname">Name</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        size="small"
        placeholder="Search by member no or Surname"
        value={searchQuery}
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
        autoFocus
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleSearchButtonClick} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          ".MuiOutlinedInput-root": {
            borderRadius: "4px",
            paddingRight: 0,
          },
        }}
      />
    </Box>
  );
};

export default CardSearch;
