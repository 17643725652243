import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Box, Button, Tab, Tabs, Paper, TextField } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { fetchSettings, saveSettings } from "../../../Redux/ActionCreator";
import { styled } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";

const EditorContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: "#fff",
  borderRadius: theme.shape.borderRadius,
}));

const SaveButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  // background: "#EA4335",
  background: "#000000",
}));

const SubjectField = styled(TextField)(({ theme }) => ({
  background: "#fff",
}));

const JoinersSettingEditor = ({ htmlText, fetchSettings, saveSettings, mg_newsubject , emReissueHtml }) => {
  const [editableSubject, setEditableSubject] = useState("");
  const [localHtmlText, setLocalHtmlText] = useState("");
  const [localReissueText, setLocalReissueText] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const editorRefHtml = useRef(null);
  const editorRefReissue = useRef(null);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);
  
  
  useEffect(() => {
    if (mg_newsubject) { 
      setEditableSubject(mg_newsubject);
    }
    setLocalHtmlText(htmlText);
    setLocalReissueText(emReissueHtml);
  }, [mg_newsubject, htmlText, emReissueHtml]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onHtmlEditorChange = (content) => {
    setLocalHtmlText(content);
  };

  const onReissueEditorChange = (content) => {
    setLocalReissueText(content);
  };

  const handleSaveText = () => {
    const content = tabValue === 0 ? localHtmlText : localReissueText;
    const subject = editableSubject || 'Default Subject';
    saveSettings(content, subject);
  };

  const handleSubjectChange = (event) => {
    setEditableSubject(event.target.value);
  };

  return (
    <>
      {/* <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Text type tabs"
        indicatorColor="inherit"
        textColor="inherit"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#ccc",
          },
          "& button": {
            borderRadius: 0,
            margin: "0 8px 0 0",
            padding: "12px 16px",
            fontSize: "0.875rem",
            fontWeight: 600,
            letterSpacing: "0.05rem",
            transition: "background-color 0.3s, transform 0.2s",
            backgroundColor: "#cccccc",
            "&:hover": {
              backgroundColor: "#f4f4f4",
            },
            "&:focus": {
              backgroundColor: "#f0f0f0",
              outline: "none",
            },
            "&.Mui-selected": {
              backgroundColor: "#EA4335",
              color: "#ffffff",
            },
            "&:active": {
              backgroundColor: "#ff8d84",
            },
          },
        }}
      >
        <Tab label="Edit new joiners message" />
        <Tab label="Edit re-issue message" />
      </Tabs> */}
      <Paper sx={{ paddingTop: "20px" }} elevation={0} square>
        <SubjectField
          name="subject"
          label="Subject"
          type="text"
          fullWidth
          variant="outlined"
          value={editableSubject}
          onChange={handleSubjectChange}
        />
        {/* {tabValue === 0 && ( */}
          <EditorContainer mt={2}>
            <Editor
              apiKey="oawwl0p0jrlgvb4t95b5cq9xyfdazp02yx2mf7myv5e61hfr"
              value={localHtmlText}
              onInit={(evt, editor) => (editorRefHtml.current = editor)}
              onEditorChange={onHtmlEditorChange}
              init={{
                height: 450,
                menubar: false,
                plugins: [
                  "link", "image", "media", "table", "code", "lists", "preview"
                ],
                toolbar: "undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code",
                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            <SaveButton
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveText}
            >
              Save Changes
            </SaveButton>
          </EditorContainer>
   
        {/* {tabValue === 1 && (
          <EditorContainer mt={2}>
            <Editor
              apiKey="oawwl0p0jrlgvb4t95b5cq9xyfdazp02yx2mf7myv5e61hfr"
              value={localReissueText}
              onInit={(evt, editor) => (editorRefReissue.current = editor)}
              onEditorChange={onReissueEditorChange}
              init={{
                height: 450,
                menubar: false,
                plugins: [
                  "link", "image", "media", "table", "code", "lists", "preview"
                ],
                toolbar: "undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image code",
                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            <SaveButton
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveText}
            >
              Save Changes
            </SaveButton>
          </EditorContainer>
        )} */}
      </Paper>
    </>
  );
};

// const mapStateToProps = (state) => ({
//   htmlText: state.settings.htmlText,
//   mg_newsubject: state.settings.mg_newsubject,
//   // emReissueText: state.settings.emReissueText,
//   emReissueHtml: state.settings.emReissueHtml,
// });

const mapStateToProps = (state) => ({
  htmlText: state.settings.htmlText,
  mg_newsubject: state.settings.mg_newsubject,
  emReissueHtml: state.settings.emReissueHtml,
});


const mapDispatchToProps = {
  fetchSettings,
  saveSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinersSettingEditor);
