import { MAKE_REQ, REQ_GETALL_SUCC, REQ_GETALL_FAIL,GET_ACTIVE_USER_SUCC } from "./ActionType";

const initialState = {
    isLoading: false,
    emails: [],
    activeUser: null,
    errorMessage: ''
};

const emailReducer = (state = initialState, action) => {
    switch (action.type) {
        case MAKE_REQ:
            return { ...state, isLoading: true };
        case REQ_GETALL_SUCC:
            return { 
                ...state, 
                isLoading: false, 
                emails: action.payload,
                total: action.payload.total,
                errorMessage: ''
            };
        case REQ_GETALL_FAIL:
            return { ...state, isLoading: false, errorMessage: action.payload };
            case GET_ACTIVE_USER_SUCC:
            return { 
                ...state, 
                isLoading: false, 
                activeUser: action.payload,
                errorMessage: ''
            };
        default:
            return state;
    }
};

export default emailReducer;
