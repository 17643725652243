import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import DDEmailInfo from "./DDEmailInfo";
import DDEmailSearch from "./DDEmailSearch";
import DDEmailImport from "./DDEmailImport";
import DDEmailDownload from "./DDEmailDownload";
import { getStatusStyle } from "../StatusStyle";
import RefreshIcon from "@mui/icons-material/Refresh";
import { fetchDDEmails, getDDEmailDetails } from "../../../../Redux/ActionCreator";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#000",
  color: theme.palette.common.white,
  fontWeight: "400",
  fontSize: "0.75em",
  "& .MuiTableSortLabel-root": {
    "&:hover": {
      color: "#ccc",
    },
    "&.Mui-active": {
      color: "#ccc",
      "& .MuiTableSortLabel-icon": {
        color: "#ddd",
      },
    },
  },
}));

const TableCellInner = styled(TableCell)(({ theme }) => ({
  padding: "5px 10px",
  fontSize: "0.8em",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

const DDEmailTable = ({
  ddEmailList,
  loadDDEmails,
  getDDEmailDetailsAction,
}) => {
  const dispatch = useDispatch();
  const { ddEmails, total, isLoading } = useSelector((state) => state.ddEmail);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchQuery, setSearchQuery] = useState("");
  const [field, setField] = useState("member_no");
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);

  useEffect(() => {
    loadDDEmails(
      searchQuery,
      field,
      filter,
      page + 1,
      rowsPerPage,
      orderBy,
      order
    );
  }, [
    searchQuery,
    field,
    filter,
    page,
    rowsPerPage,
    orderBy,
    order,
    loadDDEmails,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (query, field) => {
    setSearchQuery(query);
    setField(field);
    setPage(0);
  };

  const handleRefresh = () => {
    loadDDEmails(
      searchQuery,
      field,
      filter,
      page + 1,
      rowsPerPage,
      orderBy,
      order
    );
  };

  const getEmailStatus = (email) => {
    if (email.status === "failed") {
      if (email.severity === "permanent") {
        return "Failed (P)";
      } else if (email.severity === "temporary") {
        return "Failed (T)";
      } else {
        return "Failed";
      }
    }
    return email.status;
  };

  const handleClickOpen = (email) => {
    setSelectedEmail(email);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CustomContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    background: "#fff",
    borderRadius: theme.shape.borderRadius,
  }));

  const GlassmorphicCell = styled("span")(({ status, theme }) => ({
    display: "inline-block",
    padding: "6px 12px",
    borderRadius: "20px",
    fontSize: "0.8em",
    fontWeight: "700",
    color: theme.palette.white.main,
    textAlign: "center",
    textTransform: "uppercase",
    backgroundColor: getStatusStyle(status),
    backdropFilter: "blur(4px)",
    width: "100%",
  }));

  return (
    <CustomContainer>
      <Stack
        direction="row"
        spacing={2}
        mt={2}
        mb={2}
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <DDEmailImport refreshData={handleRefresh} />
          <DDEmailDownload />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <DDEmailSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
            setField={setField}
            field={field}
          />
        </Box>
      </Stack>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  "ID",
                  "Member No",
                  "Forename",
                  "Surname",
                  "Email",
                  "Status",
                  "Created On",
                  "Info",
                ].map((label, index) => (
                  <StyledTableCell key={index}>
                    <TableSortLabel
                      active={orderBy === label.toLowerCase()}
                      direction={
                        orderBy === label.toLowerCase() ? order : "asc"
                      }
                      onClick={(event) =>
                        handleRequestSort(event, label.toLowerCase())
                      }
                    >
                      {label}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ddEmails.map((email) => (
                <StyledTableRow
                  key={email.id}
                  hover
                  onClick={() => handleClickOpen(email)}
                >
                  <TableCellInner>{email.id}</TableCellInner>
                  <TableCellInner>{email.member_no}</TableCellInner>
                  <TableCellInner>{email.forename}</TableCellInner>
                  <TableCellInner>{email.surname}</TableCellInner>
                  <TableCellInner>{email.email}</TableCellInner>
                  <TableCellInner>
                    <GlassmorphicCell
                      status={
                        email.status === "failed"
                          ? `${email.status} ${email.severity}`
                          : email.status
                      }
                    >
                      {getEmailStatus(email)}
                    </GlassmorphicCell>
                  </TableCellInner>
                  <TableCellInner>{email.createdon}</TableCellInner>
                  <TableCellInner>
                    <IconButton onClick={() => handleClickOpen(email)}>
                      <InfoIcon />
                    </IconButton>
                  </TableCellInner>
                </StyledTableRow>
              ))}
              {ddEmails.length === 0 && !isLoading && (
                <StyledTableRow>
                  <TableCell colSpan={8} align="center">
                    No DD email available
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            borderTop: "1px solid #eee",
            backgroundColor: " #dfdfdf",
          }}
        >
          <IconButton
            onClick={() =>
              dispatch(
                fetchDDEmails(
                  searchQuery,
                  field,
                  "",
                  page + 1,
                  rowsPerPage,
                  orderBy,
                  order.toUpperCase()
                )
              )
            }
            aria-label="refresh"
            color="#aaa"
            sx={{ marginRight: 2 }}
          >
            <RefreshIcon />
          </IconButton>
          <TablePagination
            rowsPerPageOptions={[100, 200, 500]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from} – ${to} of ${count}`}
            sx={{
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-input': {
                fontSize: '0.8rem'
              },
            }} 
          />
        </Box>
      </Paper>
      <DDEmailInfo
        open={open}
        selectedEmail={selectedEmail}
        handleClose={handleClose}
      />
    </CustomContainer>
  );
};

const mapStateToProps = (state) => ({
  ddEmail: state.ddEmail,
});

const mapDispatchToProps = (dispatch) => ({
  loadDDEmails: (term, field, filter, page, limit, sort, dir) =>
    dispatch(fetchDDEmails(term, field, filter, page, limit, sort, dir)),
  getDDEmailDetailsAction: (emailId) => dispatch(getDDEmailDetails(emailId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DDEmailTable);