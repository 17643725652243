import { useState } from "react";
import JoinersSettingEditor from "./JoinersSettingEditor";
import DDNotice from "./DDNotice";
import APIToken from "./APIToken";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";

const NewJoinersSettings = () => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const initialPlainText = "Initial plain text message here.";
  const initialHtmlText = "<p><strong>Initial HTML content here.</strong></p>";

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Text type tabs"
        indicatorColor="inherit"
        textColor="inherit"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "#ccc",
          },
          "& button": {
            borderRadius: 0,
            margin: "0 8px 0 0",
            padding: "12px 16px",
            fontSize: "0.875rem",
            fontWeight: 600,
            letterSpacing: "0.05rem",
            transition: "background-color 0.3s, transform 0.2s",
            backgroundColor: "#cccccc",
            "&:hover": {
              backgroundColor: "#f4f4f4",
            },
            "&:focus": {
              backgroundColor: "#f0f0f0",
              outline: "none",
            },
            "&.Mui-selected": {
              backgroundColor: "#000000",
              color: "#ffffff",
            },
            "&:active": {
              backgroundColor: "#474747",
            },
          },
        }}
      >
        <Tab label="New Member Email" />
        <Tab label="DD Notification" />
        <Tab label="API Token" />
      </Tabs>
      <Paper sx={{ padding: "20px" }} elevation={0} square>
        {tabValue === 0 && (
          <JoinersSettingEditor
          initialPlainText={initialPlainText}
          initialHtmlText={initialHtmlText}
        />
        )}
        {tabValue === 1 && (
          <DDNotice />
        )}
        {tabValue === 2 && (
          <APIToken />
        )}
      </Paper>
    </>
  );
};

export default NewJoinersSettings;
