import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUser } from '../../Redux/ActionCreator'; 
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate, useLocation } from "react-router-dom";
// import { ColorModeContext } from '../../theme'; 

import Profilebutton from "./Profilebutton";
import gmblogo from "../../assets/gmb-logo.png";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingLeft: '60px',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    paddingLeft: '0',
  }),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: '1px solid #ddd'
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const routeTitles = {
  "/": "Dashboard",
  "/cards": "Card Management",  // Ensure paths match those in menuItems and your actual routing setup
  "/email": "Email Management",
  "/userlist": "User Management",
  "/settings": "Settings",
  "/logs": "Logs"
};


export default function SideBar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.emails.activeUser); 
  const [open, setOpen] = useState(localStorage.getItem('drawerOpen') === 'true');
  const navigate = useNavigate();
  const location = useLocation();
  // const { toggleColorMode } = useContext(ColorModeContext);

  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    dispatch(getActiveUser());
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem('drawerOpen', 'true');
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem('drawerOpen', 'false');
  };

  const menuItems = [
    { icon: <DashboardIcon />, text: "Dashboard", path: "/" },
    { icon: <CreditCardIcon />, text: "Card Management", path: "/cards" },
    { icon: <MailOutlineIcon />, text: "Email Management", path: "/email" },
    ...(activeUser && activeUser.user_type === 'admin' ? [{ icon: <RecentActorsIcon />, text: "Users", path: "/userlist" }] : []),
    ...(activeUser && activeUser.user_type === 'admin' ? [{ icon: <SettingsIcon />, text: "Settings", path: "/settings" }] : []),
    ...(activeUser && activeUser.user_type === 'admin' ? [{ icon: <ErrorIcon />, text: "Logs", path: "/logs" }] : []),
  ];

  const currentTitle = routeTitles[location.pathname] || "GMB Members Application";

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open} elevation={0}> {/* elevation explicitly set to 0 */}
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h3" noWrap component="h1">
            {currentTitle}
          </Typography>
          <Profilebutton />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src={gmblogo} width={50} height={50} alt="Logo" />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                display: "block",
                padding: '0.5rem 0.625rem;',
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  backgroundColor: isActive(item.path) ? '#000' : 'inherit',
                  color: isActive(item.path) ? theme.palette.orange.contrastText : 'inherit',
                  borderRadius: '0.375rem',
                  boxShadow: isActive(item.path) ? 'rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(255, 255, 255, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#ccc',
                    color: '#000',
                  }
                }}
                onClick={() => navigate(item.path)}
              >
                <ListItemIcon
                  sx={{ color: 'inherit', minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0, '& .MuiTypography-root': { fontSize: '0.9em' } }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
