import { useState } from "react";
import { connect } from "react-redux";
import { fetchToken } from "../../../Redux/ActionCreator"; // Adjust path as necessary
import {
  Button,
  TextField,
  IconButton,
  Box,
  Snackbar,
  Alert,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const GenerateButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: "#EA4335",
}));

const APIToken = ({ token, fetchToken }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const formatToken = (token) => {
    if (!token) {
      return ""; // Or return some placeholder like "Token not available"
    }
    const firstFour = token.slice(0, 10);
    const masked = firstFour + "*".repeat(token.length - 4);
    return masked;
  };
  

  const handleGenerateToken = () => {
    fetchToken(); // Dispatch Redux action to fetch the token
  };

  const copyToClipboard = () => {
    if (token) {
      navigator.clipboard.writeText(token);
      setSnackbarOpen(true);
    } else {
      // Optionally alert the user that there is no token to copy
      alert("No token available to copy.");
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
        margin: "0 auto",
      }}
    >
      <TextField
        label="API Token"
        value={formatToken(token)}
        InputProps={{
          readOnly: true,
          endAdornment: token ? (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                onClick={copyToClipboard}
                aria-label="copy to clipboard"
              >
                <ContentCopyIcon />
              </IconButton>
            </InputAdornment>
          ) : null, // Do not show the copy icon if there is no token
        }}
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <GenerateButton variant="contained" onClick={handleGenerateToken}>
        Generate Token
      </GenerateButton>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Token copied to clipboard
        </Alert>
      </Snackbar>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  token: state.settings.token, // Adjust according to where token is stored in your Redux state
});

const mapDispatchToProps = {
  fetchToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(APIToken);
