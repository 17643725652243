import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getEmailList, getEmailDetails } from "../../../../Redux/ActionCreator";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  IconButton,
  Box,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import EmailNewInfo from "./EmailNewInfo";
import EmailNewSearch from "./EmailNewSearch";
import EmailNewDownload from "./EmailNewDownload";
import EmailNewImport from "./EmailNewImport";
import { getStatusStyle } from "../StatusStyle";
import RefreshIcon from "@mui/icons-material/Refresh";

import { styled } from "@mui/material/styles";

const EmailNewTable = ({ emailList, loadEmails, getEmailDetailsAction }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [field, setField] = useState("member_no");
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [open, setOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);

  useEffect(() => {
    loadEmails(
      searchQuery,
      field,
      filter,
      page + 1,
      rowsPerPage,
      orderBy,
      order
    );
  }, [
    searchQuery,
    field,
    filter,
    page,
    rowsPerPage,
    orderBy,
    order,
    loadEmails,
  ]);

  const handleRefresh = () => {
    loadEmails(
      searchQuery,
      field,
      filter,
      page + 1,
      rowsPerPage,
      orderBy,
      order
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    loadEmails(searchQuery, 1, rowsPerPage, property, newOrder);
  };

  const handleSearch = () => {
    setPage(0);
    loadEmails(searchQuery, field, filter, 1, rowsPerPage, orderBy, order);
  };

  const handleClickOpen = (email) => {
    setSelectedEmail(email);
    getEmailDetailsAction(email.id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { results = [], total = 0 } = emailList || {};

  const getEmailStatus = (email) => {
    if (email.status === "failed") {
      if (email.severity === "permanent") {
        return "Failed (P)";
      } else if (email.severity === "temporary") {
        return "Failed (T)";
      } else {
        return "Failed";
      }
    }
    return email.status;
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#000",
    color: theme.palette.common.white,
    fontWeight: "400",
    fontSize: "0.75em",
    "& .MuiTableSortLabel-root": {
      "&:hover": {
        color: "#ccc",
      },
      "&.Mui-active": {
        color: "#ccc",
        "& .MuiTableSortLabel-icon": {
          color: "#ddd",
        },
      },
    },
  }));

  const TableCellInner = styled(TableCell)(({ theme }) => ({
    padding: "5px 10px",
    fontSize: "0.8em",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  }));

  const GlassmorphicCell = styled("span")(({ status, theme }) => ({
    display: "inline-block",
    padding: "6px 12px",
    borderRadius: "20px",
    fontSize: '0.8em',
    fontWeight: '700',
    color: theme.palette.white.main,
    textAlign: "center",
    textTransform: "uppercase",
    backgroundColor: getStatusStyle(status),
    backdropFilter: "blur(4px)",
    // boxShadow: 'rgba(96, 125, 139, 0.2) 0px 14px 12px',
    width: "100%",
  }));

  const CustomContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    background: "#fff",
    borderRadius: theme.shape.borderRadius,
  }));

  return (
    <CustomContainer>
      <Stack
        direction="row"
        spacing={2}
        mt={2}
        mb={2}
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <EmailNewImport refreshData={handleRefresh} />
          <EmailNewDownload />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmailNewSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
            field={field}
            setField={setField}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>
      </Stack>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { id: "id", label: "ID" },
                  { id: "member_no", label: "MEMBER NO" },
                  { id: "name", label: "NAME" },
                  { id: "email", label: "EMAIL" },
                  { id: "type", label: "TYPE" },
                  { id: "batch", label: "BATCH NO" },
                  { id: "status", label: "STATUS" },
                  { id: "createdon", label: "CREATED ON" },
                  { id: "info", label: "INFO" },
                ].map((headCell) => (
                  <StyledTableCell key={headCell.id}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((email, index) => (
                <StyledTableRow key={index}>
                  <TableCellInner>{email.id}</TableCellInner>
                  <TableCellInner>{email.member_no}</TableCellInner>
                  <TableCellInner>{`${email.forename} ${email.surname}`}</TableCellInner>
                  <TableCellInner>{email.email}</TableCellInner>
                  <TableCellInner>{email.type}</TableCellInner>
                  <TableCellInner>{email.batch_no}</TableCellInner>
                  <TableCellInner>
                    <GlassmorphicCell
                      status={
                        email.status === "failed"
                          ? `${email.status} ${email.severity}`
                          : email.status
                      }
                    >
                      {getEmailStatus(email)}
                    </GlassmorphicCell>
                  </TableCellInner>
                  <TableCellInner>{email.createdon}</TableCellInner>
                  <TableCellInner>
                    <IconButton
                      onClick={() => handleClickOpen(email)}
                      aria-label="info"
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCellInner>
                </StyledTableRow>
              ))}
              {results.length === 0 && (
                <StyledTableRow>
                  <TableCell colSpan={9} align="center">
                    No new member email available
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            borderTop: "1px solid #eee",
            backgroundColor: " #dfdfdf",
          }}
        >
          <IconButton
            onClick={handleRefresh}
            aria-label="refresh"
            color="#aaa"
            sx={{ marginRight: 2 }}
          >
            <RefreshIcon />
          </IconButton>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[100, 200, 500]}
            labelDisplayedRows={({ from, to, count }) => `${from} – ${to} of ${count}`}
            sx={{
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-input': {
                fontSize: '0.8rem'
              },
            }} 
          />
        </Box>
      </Paper>
      <EmailNewInfo
        open={open}
        selectedEmail={selectedEmail}
        handleClose={handleClose}
      />
    </CustomContainer>
  );
};

const mapStateToProps = (state) => ({
  emailList: state.emails.emails,
  total: state.emails.total,
  isLoading: state.emails.isLoading,
  errorMessage: state.emails.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  loadEmails: (term, field, filter, page, limit, sort, dir) =>
    dispatch(getEmailList(term, field, filter, page, limit, sort, dir)),
  getEmailDetailsAction: (emailId) => dispatch(getEmailDetails(emailId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailNewTable);
