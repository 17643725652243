import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = sessionStorage.getItem('jwtToken');
  const location = useLocation();

  if (!token) {
    // Redirect to login page if no token is present
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Component {...rest} />;
};


export default PrivateRoute;


// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const token = sessionStorage.getItem('jwtToken');  // Consider switching to a secure cookie
//   const location = useLocation();

//   // If there's no token, redirect to the login page, but pass the current location they were trying to access
//   if (!token) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   // Optionally, validate the token's integrity or expiration here if possible
//   return <Component {...rest} />;
// };
