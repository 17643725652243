import { useDispatch } from "react-redux";
import { deleteCard } from "../../../Redux/ActionCreator";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#444", // Dark background
  color: "#ffffff", // White text
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2), // Uniform padding
}));

const CloseButton = styled(Button)({
  backgroundColor: "#e0e0e0", // Light grey background
  color: "#212121", // Dark text
  "&:hover": {
    backgroundColor: "#bdbdbd", // Darker grey on hover
  },
  fontWeight: "bold",
});

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main, // Use theme's error color
  color: theme.palette.getContrastText(theme.palette.error.main),
  "&:hover": {
    backgroundColor: theme.palette.error.dark, // Darken on hover
  },
  fontWeight: "bold",
}));

const DeleteCard = ({ open, handleClose, memberNo, platform, onDeleteSuccess }) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteCard(platform, memberNo))
      .then(() => {
        toast.success("Card deleted successfully!");
        handleClose();
        onDeleteSuccess(); // Call the callback function to refresh the table
      })
      .catch((error) => {
        toast.error(`Delete failed: ${error.message}`);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledDialogTitle>{"Confirm Delete"}</StyledDialogTitle>
      <DialogContent sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <DialogContentText sx={{ marginTop: 2, marginBottom: 2 }}>
          Are you sure you want to delete the card for {memberNo} on {platform}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={handleClose}>Cancel</CloseButton>
        <DeleteButton onClick={handleDelete} autoFocus>
          Delete
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCard;
