import { useState } from 'react';
import { InputAdornment, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchLogs = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onSearch(searchTerm);
        }
    };

    const handleSearchClick = () => {
        onSearch(searchTerm);
    };

    return (
        <TextField
            fullWidth
            size="small"
            label="Search Logs"
            placeholder="Search logs"
            type="search"
            variant="outlined"
            value={searchTerm}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={handleSearchClick} edge="end">
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            sx={{
                ".MuiOutlinedInput-root": {
                    borderRadius: "4px",
                },
            }}
        />
    );
};

export default SearchLogs;
