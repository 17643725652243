import React, { useState } from 'react';
import {
  Box,
  TextField,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px 26px 8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const DDEmailSearch = ({ searchQuery, setSearchQuery, handleSearch, setField, field }) => {
  const [localQuery, setLocalQuery] = useState(searchQuery);

  const handleChange = (event) => {
    setLocalQuery(event.target.value);
    if (event.target.value.length >= 1) {
      handleSearch(event.target.value, field);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && localQuery.length >= 1) {
      handleSearch(localQuery, field);
    }
  };

  const handleFieldChange = (event) => {
    setField(event.target.value);
    if (localQuery.length >= 1) {
      handleSearch(localQuery, event.target.value);
    }
  };

  return (
    <Box sx={{ bgcolor: '#fff', borderRadius: '4px', display: 'flex', alignItems: 'center', gap: 1, p: 1 }}>
      <FormControl size="small" variant="standard" sx={{ minWidth: 130 }}>
        <Select value={field} onChange={handleFieldChange} input={<BootstrapInput />}>
          <MenuItem value="member_no">Member No</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="surname">Surname</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        size="small"
        placeholder="Search DD Email by member no, email, or surname"
        value={localQuery}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        autoFocus
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => localQuery.length >= 3 && handleSearch(localQuery, field)}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ '.MuiOutlinedInput-root': { borderRadius: '4px', paddingRight: 0 } }}
      />
    </Box>
  );
};

export default DDEmailSearch;
