import {
  MAKE_REQ,
  REQ_GETUSERS_SUCC,
  REQ_GETUSERS_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "./ActionType";

const initialState = {
  isLoading: false,
  users: [],
  total: 0,
  errorMessage: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_REQ:
      return { ...state, isLoading: true };
    case REQ_GETUSERS_SUCC:
      return {
        ...state,
        isLoading: false,
        users: action.payload.users,
        total: action.payload.total,
        errorMessage: "",
      };
    case REQ_GETUSERS_FAIL:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
