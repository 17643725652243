import { configureStore } from "@reduxjs/toolkit";
import activeUserReducer from './ActiveUserReducer'; 
import userReducer from './UserReducer';
import emailReducer from './EmailReducer';
import ddEmailReducer from './DDEmailReducer';
import cardReducer from './CardReducer';
import settingsReducer from './SettingsReducer';
import logReducer from "./LogReducer";
import cardHistoryReducer from './cardHistoryReducer';
import TotalCardReducer from './TotalCardReducer';

const store = configureStore({
  reducer: {
    activeUser: activeUserReducer,
    users: userReducer,
    emails: emailReducer,
    ddEmail: ddEmailReducer,
    cards: cardReducer,
    settings: settingsReducer,
    log: logReducer,
    cardHistory: cardHistoryReducer,
    totalCards: TotalCardReducer,
  }
});

export default store;
