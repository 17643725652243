import { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import {
  getCardList,
  getCardDetails,
  uploadExpireCSV,
} from "../../../Redux/ActionCreator";
import CardInfo from "./CardInfo";
import DeleteCard from "./DeleteCard";
import UploadDialog from "./UploadDialog";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import CardSearch from "./CardSearch";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import RefreshIcon from "@mui/icons-material/Refresh";

const getStatusStyle = (status) => {
  switch (status) {
    case "active":
      return "#00b72a";
    case "requested":
      return "#1976D2";
    case "inactive":
      return "#757575";
    default:
      return "#9E9E9E";
  }
};

const CardTable = ({ cardList, total, loadCards, getCardDetailsAction }) => {
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [selectedMemberNo, setSelectedMemberNo] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchQuery, setSearchQuery] = useState("");
  const [field, setField] = useState("member_no");
  const [filter, setFilter] = useState("");
  const [memberNo, setMemberNo] = useState("");
  const [platform, setPlatform] = useState("");
  const [openUploadDialog, setOpenUploadDialog] = useState(false);

  const refreshButtonRef = useRef(null);

  useEffect(() => {
    loadCards("", "", "", page + 1, rowsPerPage, orderBy, order);
  }, [page, rowsPerPage, orderBy, order, loadCards]);

  useEffect(() => {
    if (!searchQuery || searchQuery.length >= 1) {
      loadCards(
        searchQuery,
        field,
        filter,
        page + 1,
        rowsPerPage,
        orderBy,
        order
      );
    }
  }, [
    searchQuery,
    field,
    filter,
    page,
    rowsPerPage,
    orderBy,
    order,
    loadCards,
  ]);

  const handleSearch = () => {
    if (searchQuery.length < 3) {
      return;
    }
    setPage(0);
    loadCards(searchQuery, field, filter, 1, rowsPerPage, orderBy, order);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    loadCards("", "", "", 1, rowsPerPage, property, isAsc ? "desc" : "asc");
  };

  const handleOpenDelete = (card) => {
    setOpenDelete(true);
    setMemberNo(card.member_no);
    setPlatform(card.platform.toLowerCase());
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  const GlassmorphicCell = styled("span")(({ status, theme }) => ({
    display: "inline-block",
    padding: "6px 12px",
    borderRadius: "20px",
    fontSize: "0.8em",
    fontWeight: "700",
    color: theme.palette.white.main,
    textAlign: "center",
    textTransform: "uppercase",
    backgroundColor: getStatusStyle(status),
    backdropFilter: "blur(4px)",
    width: "100%",
  }));

  const handleOpenInfoDialog = (card) => {
    if (card && card.member_no) {
      getCardDetailsAction(card.member_no);
      setSelectedMemberNo(card.member_no);
      setOpenInfoDialog(true);
    } else {
      console.error("Card data is missing or incomplete:", card);
    }
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#000",
    color: theme.palette.common.white,
    fontWeight: "400",
    fontSize: "0.75em",

    "& .MuiTableSortLabel-root": {
      "&:hover": {
        color: "#ccc",
      },
      "&.Mui-active": {
        color: "#ccc",
        "& .MuiTableSortLabel-icon": {
          color: "#ddd",
        },
      },
    },
  }));

  const TableCellInner = styled(TableCell)(({ theme }) => ({
    padding: "5px 10px",
    fontSize: "0.8em",
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  }));

  const CustomContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    background: "#fff",
    borderRadius: theme.shape.borderRadius,
  }));

  // const handleRefresh = () => {
  //   loadCards(
  //     searchQuery,
  //     field,
  //     filter,
  //     page + 1,
  //     rowsPerPage,
  //     orderBy,
  //     order
  //   );
  // };
  const handleRefresh = useCallback(() => {
    loadCards(
      searchQuery,
      field,
      filter,
      page + 1,
      rowsPerPage,
      orderBy,
      order
    );
  }, [
    loadCards,
    searchQuery,
    field,
    filter,
    page,
    rowsPerPage,
    orderBy,
    order,
  ]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <CustomContainer>
      <Stack
        direction="row"
        spacing={2}
        mt={2}
        mb={2}
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<PublishIcon />}
            onClick={() => setOpenUploadDialog(true)}
            sx={{ backgroundColor: "#20a1a1", color: "#ffffff" }}
          >
            Upload Expiry CSV
          </Button>
          <UploadDialog
            open={openUploadDialog}
            handleClose={() => {
              setOpenUploadDialog(false);
              loadCards("", "", "", page + 1, rowsPerPage, orderBy, order);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CardSearch
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
            field={field}
            setField={setField}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>
      </Stack>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {[
                  { id: "id", label: "ID", sortable: true },
                  { id: "member_no", label: "MEMBER NO", sortable: true },
                  { id: "forename", label: "FULL NAME", sortable: true },
                  { id: "region", label: "REGION", sortable: true },
                  { id: "platform", label: "PLATFORM", sortable: true },
                  { id: "issue_id", label: "ISSUE ID", sortable: false },
                  { id: "status", label: "STATUS", sortable: true },
                  { id: "createdon", label: "CREATED ON", sortable: true },
                  { id: "info", label: "INFO", sortable: false },
                ].map((headCell) => (
                  <StyledTableCell
                    key={headCell.id}
                    sortDirection={
                      headCell.sortable && orderBy === headCell.id
                        ? order
                        : false
                    }
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={(event) =>
                          handleRequestSort(event, headCell.id)
                        }
                      >
                        {headCell.label}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {cardList.map((card, index) => (
                <StyledTableRow key={index}>
                  <TableCellInner>{card.id}</TableCellInner>
                  <TableCellInner>{card.member_no}</TableCellInner>
                  <TableCellInner>{`${card.forename} ${card.surname}`}</TableCellInner>
                  <TableCellInner>{card.region}</TableCellInner>
                  <TableCellInner>{card.platform}</TableCellInner>
                  <TableCellInner>{card.issue_id}</TableCellInner>
                  <TableCellInner>
                    <GlassmorphicCell status={card.status}>
                      {card.status}
                    </GlassmorphicCell>
                  </TableCellInner>
                  <TableCellInner>{card.createdon}</TableCellInner>
                  <TableCellInner>
                    <IconButton
                      aria-label="info"
                      onClick={() => handleOpenInfoDialog(card)}
                    >
                      <InfoIcon />
                    </IconButton>

                    {card.status === "active" && (
                      <IconButton
                        aria-label="expire"
                        onClick={() => handleOpenDelete(card)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCellInner>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            borderTop: "1px solid #eee",
            backgroundColor: " #dfdfdf",
          }}
        >
          <IconButton
            ref={refreshButtonRef}
            onClick={handleRefresh}
            sx={{ color: "#aaa" }}
          >
            <RefreshIcon />
          </IconButton>
          <TablePagination
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
            rowsPerPageOptions={[100, 200, 500]}
            labelDisplayedRows={({ from, to, count }) =>
              `${from} – ${to} of ${count}`
            }
            sx={{
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-input': {
                fontSize: '0.8rem'
              },
            }}
          />
        </Box>
      </Paper>
      <DeleteCard
        open={openDelete}
        handleClose={handleClose}
        memberNo={memberNo}
        platform={platform}
        onDeleteSuccess={() => handleRefresh()}
      />

      <CardInfo
        open={openInfoDialog}
        handleClose={handleCloseInfoDialog}
        selectedMemberNo={selectedMemberNo}
      />
    </CustomContainer>
  );
};

const mapStateToProps = (state) => ({
  cardList: state.cards.cards,
  total: state.cards.total,
});

const mapDispatchToProps = (dispatch) => ({
  loadCards: (term, field, filter, page, limit, sort, dir) =>
    dispatch(getCardList(term, field, filter, page, limit, sort, dir)),
  getCardDetailsAction: (cardId) => dispatch(getCardDetails(cardId)),
  uploadExpireCSV: (file) => dispatch(uploadExpireCSV(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardTable);
