import {
  MAKE_REQ,
  FETCH_SETTINGS_SUCCESS,
  FETCH_SETTINGS_FAILURE,
  FETCH_TOKEN_REQUEST,
  FETCH_TOKEN_SUCCESS,
  FETCH_TOKEN_FAILURE,
} from "./ActionType";

const initialState = {
  text: "",
  htmlText: "",
  mg_newsubject: "",
  emReissueText: "",
  emReissueHtml: "",
  em_ddhtml: "",
  mg_ddsubject: "",
  error: null,
  token: '',
  isLoadingToken: false,
  tokenError: null,
};


const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_REQ:
      return { ...state, isLoading: true };
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        text: action.payload.em_newtext,
        htmlText: action.payload.em_newhtml,
        mg_newsubject: action.payload.mg_newsubject,
        emReissueText: action.payload.em_reissuetext,
        emReissueHtml: action.payload.em_reissuehtml,
        em_ddhtml: action.payload.em_ddhtml,
        mg_ddsubject: action.payload.mg_ddsubject,
        error: null,
      };
    case FETCH_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_TOKEN_REQUEST:
      return { ...state, isLoadingToken: true };
    case FETCH_TOKEN_SUCCESS:
      return { ...state, token: action.payload, isLoadingToken: false };
    case FETCH_TOKEN_FAILURE:
      return { ...state, tokenError: action.payload, isLoadingToken: false };
    default:
      return state;
  }
};

export default settingsReducer;
