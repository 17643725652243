import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { uploadExpireCSV } from "../../../Redux/ActionCreator";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

const Input = styled("input")({
  display: "none",
});

const DottedBox = styled(Box)(({ theme }) => ({
  border: "2px dashed #aaa",
  borderRadius: "3px",
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: 140,
  gap: theme.spacing(2),
}));

const ErrorList = styled(Box)({
  maxHeight: 220,
  overflowY: "auto",
  border: "1px solid #ccc",
  marginTop: 16,
  padding: 8,
  backgroundColor: "#fff",
  borderRadius: 4,
});

const StyledListItem = styled(ListItem)({
  borderBottom: "1px solid #eee",
  "&:last-child": {
    borderBottom: "none",
  },
});

const UploadDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".csv")) {
      setFile(file);
      setIsFilePicked(true);
    } else {
      toast.error("Please select a CSV file.");
      setIsFilePicked(false);
    }
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const response = await dispatch(uploadExpireCSV(file));
        setUploadResponse(response);
        toast.success("File uploaded successfully!");
      } catch (error) {
        toast.error(`Upload failed: ${error.message}`);
      }
    } else {
      toast.error("No file selected or invalid file type.");
    }
  };

  const resetDialog = () => {
    setFile(null);
    setIsFilePicked(false);
    setUploadResponse(null);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={resetDialog}
      aria-labelledby="import-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        id="import-dialog-title"
        sx={{ backgroundColor: "#444", color: "#ffffff" }}
      >
        {uploadResponse ? "Upload Response" : "Import CSV"}
        <IconButton
          aria-label="close"
          onClick={resetDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {!uploadResponse ? (
          <DottedBox
            onClick={() => fileInputRef.current.click()}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(event) => {
              event.preventDefault();
              const files = event.dataTransfer.files;
              if (files.length > 0 && files[0].name.endsWith(".csv")) {
                setFile(files[0]);
                setIsFilePicked(true);
              } else {
                toast.error("Please drop a CSV file.");
              }
            }}
          >
            <CloudUploadIcon color="disabled" style={{ fontSize: 48 }} />
            <Typography>Drag and drop or click to browse your file.</Typography>
            <Input
              accept=".csv"
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </DottedBox>
        ) : (
          <Box>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Total: {uploadResponse.total}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Requested: {uploadResponse.requested}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Inactive: {uploadResponse.inactive}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Error Count: {uploadResponse.error_cnt}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              variant="subtitle1"
              sx={{ mt: 2, mb: 1, fontWeight: "medium" }}
            >
              List of Errors:
            </Typography>
            <ErrorList>
              <List dense>
                {uploadResponse.errors.map((error, index) => (
                  <StyledListItem key={index}>
                    <Typography variant="body2">{error}</Typography>
                  </StyledListItem>
                ))}
              </List>
            </ErrorList>
          </Box>
        )}
        {isFilePicked && !uploadResponse && (
          <Box display="flex" alignItems="center" gap={1} mt={1}>
            <Typography>File: {file.name}</Typography>
            <Button
              onClick={() => {
                setFile(null);
                setIsFilePicked(false);
              }}
              size="small"
              sx={{ fontSize: "0.7rem", textTransform: "none" }}
            >
              Remove
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {uploadResponse ? (
          <Button onClick={resetDialog}>Close</Button>
        ) : (
          <>
            <Button onClick={resetDialog}>Cancel</Button>
            <Button
              onClick={handleUpload}
              disabled={!isFilePicked}
              variant="contained"
              color="primary"
            >
              Upload
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UploadDialog;
