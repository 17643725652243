import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from './Redux/Store';
import { ColorModeContext, useMode } from './theme';
import Dashboard from './scenes/Pages/Dashboard';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import Card from './scenes/Pages/Cards'
import Email from './scenes/Pages/Email'
import Logs from './scenes/Pages/Logs'
import Settings from './scenes/Pages/Settings'
import UserList from './scenes/Pages/Userlist'
import Profile from './scenes/Pages/Profile'


function App() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = sessionStorage.getItem('jwtToken');
      if (!token) {
        navigate('/login');
      }
      setIsLoading(false);
    };
    checkAuthentication();
  }, [navigate]);

  const [theme, colorMode] = useMode();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<PrivateRoute component={Dashboard} />} />
              <Route path="/Cards" element={<PrivateRoute component={Card} />} />
              <Route path="/Email" element={<PrivateRoute component={Email} />} />
              <Route path="/Logs" element={<PrivateRoute component={Logs} allowedRoles={['admin']} />} />
              <Route path="/Settings" element={<PrivateRoute component={Settings} allowedRoles={['admin']} />} />
              <Route path="/Userlist" element={<PrivateRoute component={UserList} allowedRoles={['admin']} />} />
              <Route path="/Profile" element={<PrivateRoute component={Profile} />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
      <ToastContainer position="top-right" />
    </Provider>
  );
}

export default App;
