// import React from "react";
// import { Button } from "@mui/material";
// import DownloadIcon from "@mui/icons-material/Download";
// import * as XLSX from "xlsx";
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchLog } from "../../../Redux/ActionCreator";

// const DownloadLogs = () => {
//   // const dispatch = useDispatch();
//   // const logs = useSelector(state => state.log.logs); // Adjust this path based on your Redux state structure

//   // const handleDownload = async () => {
//   //   dispatch(fetchLogs()); // This will fetch the logs and update the Redux store

//   //   // We need to wait for the logs to be fetched and updated in the Redux store
//   //   setTimeout(() => {
//   //     if (logs && logs.length) {
//   //       const worksheet = XLSX.utils.json_to_sheet(logs);
//   //       const workbook = XLSX.utils.book_new();
//   //       XLSX.utils.book_append_sheet(workbook, worksheet, "Logs");
//   //       XLSX.writeFile(workbook, "logs.xlsx");
//   //     } else {
//   //       console.log("No logs to download or logs are not in an array format");
//   //     }
//   //   }, 1000); // Adjust timeout as necessary based on your application's response time
//   // };

//   const dispatch = useDispatch();

//   const handleDownload = async () => {
//     dispatch(fetch for Logs()); // This will fetch the logs and trigger a download
//   };

//   return (
//     <Button
//       variant="contained"
//       startIcon={<DownloadIcon />}
//       onClick={handleDownload}
//       sx={{ backgroundColor: "#20a1a1", color: "#ffffff" }}
//     >
//       Download logs
//     </Button>
//   );
// };

// export default DownloadLogs;


import React from "react";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";

const DownloadLogs = () => {

  const downloadLogs = async () => {
    const token = sessionStorage.getItem("jwtToken");
    const url = `${process.env.REACT_APP_API_BASE_URL}log`;

    if (token) {
      axios.post(url, {}, {
        headers: { 'Authorization': `Bearer ${token}` },
        responseType: 'blob' // Important for handling binary data
      }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = res.headers['content-disposition'];
        let filename = 'download.log'; // Default filename if no content-disposition header is present
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove(); // Clean up after triggering download
      }).catch(err => {
        console.error("Error downloading logs:", err.message);
      });
    } else {
      console.error("Authentication token not found");
    }
  };

  return (
    <Button
      variant="contained"
      startIcon={<DownloadIcon />}
      onClick={downloadLogs}
      sx={{ backgroundColor: "#20a1a1", color: "#ffffff" }}
    >
      Download logs
    </Button>
  );
};

export default DownloadLogs;
