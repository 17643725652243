import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLogs } from '../../../Redux/ActionCreator';
import { TextField, MenuItem } from '@mui/material';

const FilterLogs = () => {
    const dispatch = useDispatch();
    const [level, setLevel] = useState('');

    const handleLevelChange = (event) => {
        const selectedLevel = event.target.value;
        setLevel(selectedLevel);
        // Check if 'All' is selected and adjust parameters accordingly
        if (selectedLevel === 'ALL') {
            dispatch(fetchLogs(''));  // Assuming fetchLogs can handle empty string properly
        } else {
            dispatch(fetchLogs('', selectedLevel)); // Dispatch fetchLogs with the selected level
        }
    }

    return (
        <TextField
            select
            label="Filter by"
            value={level}
            onChange={handleLevelChange}
            fullWidth
            size="small"
            variant='outlined'
            sx={{ minWidth: 140 }}
        >
            <MenuItem value="ALL">All</MenuItem>
            <MenuItem value="INFO">Info</MenuItem>
            <MenuItem value="NOTICE">Notice</MenuItem>
            <MenuItem value="ERROR">Error</MenuItem>
        </TextField>
    );
}

export default FilterLogs;
