import {
    FETCH_CARDS_REQUEST,
    FETCH_CARDS_SUCCESS,
    FETCH_CARDS_FAIL,
    GET_CARD_DETAILS_SUCCESS,
    GET_CARD_DETAILS_FAIL,
    DELETE_CARD_SUCCESS,
    DELETE_CARD_FAIL,
    UPLOAD_EXPIRE_CSV_REQUEST,
    UPLOAD_EXPIRE_CSV_SUCCESS,
    UPLOAD_EXPIRE_CSV_FAILURE
} from "./ActionType";

const initialState = {
    isLoading: false,
    isLoadingDetails: false,
    cards: [],
    cardDetails: null,
    total: 0,
    errorMessage: '',
    errorDetailsMessage: '',
    isDeleting: false,
    deleteError: null,
    isUploadingCSV: false,
    uploadCSVError: null,
    uploadCSVSuccess: null
};

const cardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CARDS_REQUEST:
            return {
                ...state,
                isLoading: true,
                errorMessage: ''
            };
        case FETCH_CARDS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cards: action.payload.results,
                total: action.payload.total,
                errorMessage: ''
            };
        case FETCH_CARDS_FAIL:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.payload
            };
        case GET_CARD_DETAILS_SUCCESS:
            return {
                ...state,
                isLoadingDetails: false,
                cardDetails: action.payload.results,
                errorDetailsMessage: ''
            };
        case GET_CARD_DETAILS_FAIL:
            return {
                ...state,
                isLoadingDetails: false,
                errorDetailsMessage: action.payload
            };
        case DELETE_CARD_SUCCESS:
            return {
                ...state,
                isDeleting: false
            };
        case DELETE_CARD_FAIL:
            return {
                ...state,
                isDeleting: false,
                deleteError: action.payload
            };
        case UPLOAD_EXPIRE_CSV_REQUEST:
            return {
                ...state,
                isUploadingCSV: true,
                uploadCSVError: null
            };
        case UPLOAD_EXPIRE_CSV_SUCCESS:
            return {
                ...state,
                isUploadingCSV: false,
                uploadCSVSuccess: action.payload
            };
        case UPLOAD_EXPIRE_CSV_FAILURE:
            return {
                ...state,
                isUploadingCSV: false,
                uploadCSVError: action.payload
            };
        default:
            return state;
    }
};

export default cardReducer;
