import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLogs, deleteAllLogs } from "../../../Redux/ActionCreator";
import { styled } from "@mui/material/styles";
import LogDetailsDialog from "./LogDetailsDialog";
import SearchLogs from "./SearchLogs";
import DownloadLogs from "./DownloadLogs";
import FilterLogs from "./FilterLogs";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

const LogTable = () => {
  const dispatch = useDispatch();
  const { logs, loading, error, total } = useSelector((state) => state.log);
  const [open, setOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");

  useEffect(() => {
    dispatch(
      fetchLogs("", "", "", currentPage + 1, rowsPerPage, orderBy, order)
    );
  }, [dispatch, currentPage, rowsPerPage, orderBy, order]);

  const handleChangePage = (event, newPage) => setCurrentPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleRequestSort = (event, property) => {
    if (property === "actions") return;
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    dispatch(
      fetchLogs(
        "",
        "",
        "",
        currentPage + 1,
        rowsPerPage,
        property,
        isAsc ? "desc" : "asc"
      )
    );
  };

  const handleOpen = (log) => {
    setSelectedLog(log); // Set the selected log
    setOpen(true); // Then open the dialog
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedLog(null); // Optionally reset the selected log on close
  };

  const handleSearch = (searchTerm) => {
    dispatch(fetchLogs(searchTerm));
  };

  const handleDeleteAll = () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete all logs?"
    );
    if (confirmDelete) {
      dispatch(deleteAllLogs());
    }
  };

  const truncateMessage = (message, length = 50) => {
    if (message.length <= length) return message;
    return message.substring(0, length) + "...";
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: "#000",
    color: theme.palette.common.white,
    fontWeight: "400",
    fontSize: "0.75em",
    "& .MuiTableSortLabel-root": {
      "&:hover": {
        color: "#ccc",
      },
      "&.Mui-active": {
        color: "#ccc",
        "& .MuiTableSortLabel-icon": {
          color: "#ddd",
        },
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  }));
  const CustomContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    background: "#fff",
    borderRadius: theme.shape.borderRadius,
  }));

  const TableCellInner = styled(TableCell)(({ theme }) => ({
    padding: "10px",
    fontSize: "0.8em",
  }));

  return (
    <CustomContainer>
      <Box sx={{ maxWidth: "100%", mb: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <DownloadLogs />
            <Button
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteAll}
              sx={{ backgroundColor: "#20a1a1", color: "#ffffff" }}
            >
              Delete all logs
            </Button>
          </Box>

          <Box
            sx={{
              bgcolor: "#fff",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              gap: 1,
              p: 1,
            }}
          >
            <FilterLogs />
            <SearchLogs onSearch={handleSearch} />
          </Box>
        </Box>
        <Paper>
          <TableContainer sx={{ overflowX: "auto" }}>
            <Table aria-label="logs table" stickyHeader>
              <TableHead>
                <TableRow>
                  {[
                    { id: "id", label: "ID", sortable: true },
                    { id: "date", label: "DATE", sortable: false },
                    { id: "level", label: "LEVEL", sortable: true },
                    { id: "method", label: "METHOD", sortable: true },
                    { id: "username", label: "USERNAME", sortable: true },
                    { id: "ip_address", label: "IP ADDRESS", sortable: false },
                    { id: "message", label: "MESSAGE", sortable: false },
                    { id: "info", label: "INFO", sortable: false },
                  ].map((headCell) => (
                    <StyledTableCell
                      key={headCell.id}
                      sortDirection={
                        headCell.sortable && orderBy === headCell.id
                          ? order
                          : false
                      }
                    >
                      {headCell.sortable ? (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={(event) =>
                            handleRequestSort(event, headCell.id)
                          }
                        >
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : error ? (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      <Typography color="error">{error}</Typography>
                    </TableCell>
                  </TableRow>
                ) : logs && logs.length > 0 ? (
                  logs.map((log) => (
                    <StyledTableRow key={log.id}>
                      <TableCellInner>{log.id}</TableCellInner>
                      <TableCellInner>{log.createdon}</TableCellInner>
                      <TableCellInner>{log.level_name}</TableCellInner>
                      <TableCellInner>{log.method}</TableCellInner>
                      <TableCellInner>{log.username}</TableCellInner>
                      <TableCellInner>{log.ip}</TableCellInner>
                      <TableCellInner>
                        {truncateMessage(log.message)}
                      </TableCellInner>
                      <TableCellInner>
                        {log.message.length > 50 ? (
                          <Button
                            onClick={() => handleOpen(log)}
                            size="small"
                            sx={{
                              textTransform: "none",
                              fontSize: "0.75em",
                              justifyContent: "center",
                              padding: "0",
                              color: "#444",
                            }}
                          >
                            <InfoIcon />
                          </Button>
                        ) : (
                          ""
                        )}
                      </TableCellInner>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      No logs to display
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <LogDetailsDialog
              open={open}
              onClose={handleClose}
              log={selectedLog}
            />
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              borderTop: "1px solid #eee",
              backgroundColor: " #dfdfdf",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[100, 200, 500]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from} – ${to} of ${count}`
              }
              sx={{
                ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-input":
                  {
                    fontSize: "0.8rem",
                  },
              }}
            />
          </Box>
        </Paper>
      </Box>
    </CustomContainer>
  );
};

export default LogTable;
