import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../global/Sidebar";
import CardEmailCount from "./CardEmailCount";
import CardDownloadPlatform from "./CardDownloadPlatform";
import CardHistoryComponent from "./CardHistoryComponent";
import DDEmailStatusGrid from "./DDEmailStatusGrid";

const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(localStorage.getItem('drawerOpen') === 'true');

  useEffect(() => {
    const handleStorageChange = () => {
      // Update the state when the local storage changes
      setDrawerOpen(localStorage.getItem('drawerOpen') === 'true');
    };

    // Adding an event listener to listen to local storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }} mt={2}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "55px" }}>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={6} md={4}>
            <CardHistoryComponent />
          </Grid>
          <Grid item xs={6} md={4}>
            <CardEmailCount />
          </Grid>
          <Grid item xs={6} md={4}>
            <DDEmailStatusGrid />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                height: 500,
                width: drawerOpen ? "calc(100% - 10px)" : "100%",
                backgroundColor: "#fff",
                mb: 2,
                p: 3,
                pb: 8,
                borderRadius: 4,
              }}
            >
              <Typography
                component={"h3"}
                variant="h4"
                mb={2}
                fontWeight="bold"
                letterSpacing="-0.5px"
              >
                Active Members Card
              </Typography>
              <CardDownloadPlatform />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
