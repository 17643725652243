import { FETCH_LOGS_REQUEST, FETCH_LOGS_SUCCESS, FETCH_LOGS_FAIL } from './ActionType';

const initialState = {
  loading: false,
  logs: [],
  total: 0,
  error: null
};

const logReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null // Reset error on new request
      };
    case FETCH_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        logs: action.payload.results,
        total: action.payload.total, // Make sure `total` is being set here
        error: null
      };
    case FETCH_LOGS_FAIL:
      return {
        ...state,
        loading: false,
        logs: [], // Optionally clear previous logs on error
        total: 0,
        error: action.payload
      };
    default:
      return state;
  }
};

export default logReducer;
