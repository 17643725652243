// DeleteUserButton.jsx
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { deleteUser } from "../../../Redux/ActionCreator";

const DeleteUserButton = ({
  user,
  getUserList,
  currentPage,
  rowsPerPage,
  orderBy,
  order,
}) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(deleteUser(user.id))
      .then(() => {
        dispatch(getUserList(currentPage + 1, rowsPerPage, orderBy, order));
        setOpen(false);
      })
      .catch((error) => {
        console.error("Failed to delete user:", error);
      });
  };

  return (
    <>
      <Button
        startIcon={<DeleteIcon />}
        onClick={handleClickOpen}
        color="error"
      ></Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {user.username}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUserButton;
