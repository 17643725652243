import {
  FETCH_DDEMAILS_REQUEST,
  FETCH_DDEMAILS_SUCCESS,
  FETCH_DDEMAILS_FAILURE
} from './ActionType';

const initialState = {
  isLoading: false,
  ddEmails: [],
  total: 0,
  error: null,
  refreshNeeded: false 
};

const ddEmailReducer = (state = initialState, action) => {
  switch (action.type) {
      case FETCH_DDEMAILS_REQUEST:
          return { ...state, isLoading: true };
      case FETCH_DDEMAILS_SUCCESS:
          return {
              ...state,
              isLoading: false,
              ddEmails: action.payload.results,
              total: action.payload.total,
              error: null
          };
      case FETCH_DDEMAILS_FAILURE:
          return {
              ...state,
              isLoading: false,
              error: action.payload
          };
      default:
          return state;
  }
};

export default ddEmailReducer;
